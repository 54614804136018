import React from "react";
import {
  SpText,
  SpCaption,
  AuthWrapper,
  AuthPanel,
  SpButton,
} from "../../components/DesignKit";

type Props = {
  history: {
    push: Function,
  },
};

const NoMatch = ({ history }: Props) => (
  <AuthWrapper>
    <AuthPanel>
      <div className="mb-4">
        <SpText fontSize="18px" color="#000">
          Oops! Are you sure you are not lost?
        </SpText>
      </div>
      <div className="mb-5 text-center">
        <SpCaption>
          Looks like you have reached a page that doesn't exist. Please click
          the button below to go back to home page.
        </SpCaption>
      </div>
      <div>
        <SpButton
          onClick={() => {
            history.push("/");
          }}
        >
          Take me home
        </SpButton>
      </div>
    </AuthPanel>
  </AuthWrapper>
);

export default NoMatch;
