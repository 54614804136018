// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Row,
    Col,
    Spin,
    Button,
} from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { SpH5, SpError, SpButton, SpText } from '../../../components/DesignKit';
import CloseIcon from "../../../assets/imgs/close.svg";

type Props = {
    close: Function,
    submit: Function,
    visible: Boolean,
    message: String,
    type: String,
    userInfo: Object,
    shopUpdate: Function,
    exstingShop: Function,
    displayMessage: String,
};

const ToasetMessages = (props: Props) => {
    const {
        visible,
        userInfo,
        close,
        message,
        type,
        shopUpdate,
        exstingShop,
        displayMessage,
    } = props;

    const closeModal = () => {
      if (type !== 'processing') {
        close();
      }
      if (message === 'Paid') {
        location.reload();
      }
    }

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            footer={null}
            onCancel={() =>closeModal()}
        >
            {
                type !== 'processing' && type !== 'Updateshop' && (
                    <div class="d-flex justify-content-end align-items-center">
                        <img src={CloseIcon} height='20' width='20' onClick={() => closeModal()} style={{ cursor: 'pointer' }} />
                    </div>
                )
            }
            {
                message === 'newGroup' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
                <CheckCircleOutlined style={{ color: 'green', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center px-3 mb-3">
                <SpText fontSize='18px' color="#606060" fontWeight="400">Thanks for creating a new Group. Others have option to join.</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'joinedGroup' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
                <CheckCircleOutlined style={{ color: 'green', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="#000000" fontWeight="500">You have joined the group successfully!</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'Requested' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
                <CheckCircleOutlined style={{ color: 'green', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="#000000" fontWeight="500">Your request sent successfully</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'Processing' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
                <Spin />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="#000000" fontWeight="500">Processing your order</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'Success' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
                <CheckCircleOutlined style={{ color: 'green', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='16px' color="#000000" fontWeight="400">Your order placed successfully!</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'Failed' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
              <ExclamationCircleOutlined style={{ color: 'red', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="red" fontWeight="500">Unable to place your order</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'PaymentPrcess' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
              <ExclamationCircleOutlined style={{ color: 'red', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="red" fontWeight="500">Your order processed for payment</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'Notenoughstock' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
              <ExclamationCircleOutlined style={{ color: 'red', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="red" fontWeight="500">{displayMessage}</SpText>
              </Col>
            </Row>
                )
            }
            {
                message === 'Paid' && (
                    <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
              <ExclamationCircleOutlined style={{ color: 'red', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="red" fontWeight="500">The current table order is closed</SpText>
              </Col>
            </Row>
                )
            }
        {
          message === 'Updateshop' && (
            <Row type="flex" justify="space-between justify-content-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="000000" fontWeight="500">Do you wish to clear the cart ?</SpText>
              </Col>
              <Col span={24} className="d-flex justify-content-center align-items-center mt-2">
                <Button className='searchDateGradientBtn w-50' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => shopUpdate()}>
                  Yes
                </Button>&nbsp;&nbsp;
                <Button className='searchDateGradientBtn1 w-50' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => exstingShop()}>
                  No
                </Button>
              </Col>
            </Row>
          )
        }
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    userInfo: state.cartInfo.userInfo,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(ToasetMessages);
