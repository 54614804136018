import React from "react";
import { invalid } from "../../assets/imgs/images";

const InvalidPage = () => {
  return (
    <div>
      <img src={invalid} alt="invalid-slug" className="invalidSlug" />
    </div>
  );
};

export default InvalidPage;