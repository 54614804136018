import React, { createContext, useContext, useState, useEffect } from "react";

export const PriceQuantityContext = createContext();

export function PriceQuantityProvider({ children }) {
  const [wholeData, setWholeData] = useState([]);
  const [slugApiKey, setSlugApiKey] = useState("");
  const [isItemsAddedToCart, setIsItemsAddedToCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  return (
    <PriceQuantityContext.Provider
      value={{
        wholeData,
        setWholeData,
        slugApiKey,
        setSlugApiKey,
        isItemsAddedToCart,
        setIsItemsAddedToCart,
        cartItems,
        setCartItems,
      }}
    >
      {children}
    </PriceQuantityContext.Provider>
  );
}

export function usePriceQuantity() {
  return useContext(PriceQuantityContext);
}
