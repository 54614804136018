// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import {
  Layout,
  Row,
  Col,
  Select,
  Input,
} from 'antd';
import { SpButton, SpText } from '../DesignKit';
import {
  withRouter,
} from 'react-router-dom';
import styled from 'styled-components';
import type { ContextRouter } from 'react-router-dom';

import {
  notificationActions,
  cartActions,
} from '../../store/actions';

import {
  axiosNoAuthInstance,
  axiosAuthInstance,
  API_END_POINTS,
} from '../../api';
import { getOperatingTime } from "../../helper/AddToCartHelper";
import IndFlag from '../../assets/IndFlag.png';
import PakFlag from '../../assets/pakFlag.png';

const { Option } = Select;
const { Search } = Input;

// $FlowFixMe
const Header = styled(Layout.Header)({
  paddingLeft: '24px',
  paddingRight: '24px',
  height: '70px',
});

type Props = ContextRouter & {
  getCartVisible: Function,
  itemsInfos: Array<Objects>,
  isMobileView: Boolean,
  getSearchItems: Function,
  searchItem: Array<Object>,
  setSearchItems: Function,
  setShopOpen: Function,
};

const AppHeader = (props: Props) => {
  const { setSearchItems, searchItem, getSearchItems, shopInfo, getCartVisible, itemsInfos, isMobileView, setShopOpen } = props;
  const [searchValue, setSearchValue] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [accntName, setAccntName] = useState('');
  const location = useLocation();
  const { pathname } = location;
  const slugName = pathname.replace("/", "");
  const [enableHOP, setEnableHOP] = useState();
  const [storeOpen, setStoreOpen] = useState(true);
  const [timings, setTimings] = useState();
  const [timingsInfo, setTimingsInfo] = useState();
  const [bannerText, setBannerText] = useState('');
  const [selectedAccount, setSelectedAccount] = useState();

  const getStoreTimings = () => {
    const today = moment.utc(new Date()).tz(selectedAccount && selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
    // const today = moment.utc(new Date());
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    // const day = days[today._i.getDay()];
    // const minutes = today._i.getHours() * 60 + today._i.getMinutes();
    const currentDay =
      timings && timings.filter((weekDay) => weekDay.dayOfWeek === day);
      if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length > 1) {
        const array = [...currentDay[0].operatingHours];
        const tempArray = [];
        for (let i = 0; i < array.length; i += 1) {
          if (array[i].openingTime < minutes && array[i].closingTime >= minutes) {
            tempArray.push(true);
          }
        }
        tempArray.filter((item) => item === true);
        if (tempArray && tempArray.length !== 0) {
          setStoreOpen(true);
          setShopOpen(true);
          return true;
        } else {
          setStoreOpen(false);
          setShopOpen(false);
          return false;
        }
      }
    setStoreOpen(currentDay && currentDay[0] && currentDay[0].operatingHours[0] &&
      currentDay[0].operatingHours[0].openingTime < minutes &&
      currentDay[0].operatingHours[0].closingTime >= minutes);
    setShopOpen(currentDay && currentDay[0] && currentDay[0].operatingHours[0] &&
      currentDay[0].operatingHours[0].openingTime < minutes &&
      currentDay[0].operatingHours[0].closingTime >= minutes);
    return currentDay && currentDay[0] && currentDay[0].operatingHours[0] &&
      currentDay[0].operatingHours[0].openingTime < minutes &&
      currentDay[0].operatingHours[0].closingTime >= minutes
    setTimingsInfo(currentDay);
  };

  useEffect(() => {
    if (timings && enableHOP) {
      getStoreTimings();
    }
  }, [timings]);

  const getAccountDetails = async () => {
    try {
      const { data: { entity } } = await axiosNoAuthInstance.get(`${API_END_POINTS.GET_PUBLICK_KEY}/${slugName}`);
      setSelectedAccount(entity && entity.account ? entity.account : null);
      setEnableHOP(entity.enableHoursOfOperation);
      setAccntName(entity.name);
      setBannerImage(entity.banner ? entity.banner : '');
      setTimings(entity && entity.hoursOfOperations);
      setBannerText(entity && entity.bannerText ? entity.bannerText : '');
    } catch (e) {
      console.error(e);
      if (e.response.status === 412) {
        history.push("/account/invalidSlug");
      }
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, [slugName]);

  const selectedShop = localStorage.getItem('shopGid');

  useEffect(() => {
    setSearchValue('');
  }, [selectedShop]);

  const searchItems = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (searchTerm) {
      setSearchItems(true);
      try {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.GET_ITEMS_DETAILS}/${selectedShop}?name.LIKE=${searchTerm}`);
        getSearchItems(entity);
      } catch (e) {
        console.error(e);
      }
    } else {
      getSearchItems([]);
      setSearchItems(false);
    }
    
  };
  return (
    <>
      <Header className="HEADER" style={{ height: 'auto' }}>
        {
          isMobileView ? (
            <Row justify="space-between" align="middle">
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <div className="d-flex align-items-center">
                  <img src={(slugName && slugName === 'shasthafreshnewark') ?  'https://s3.amazonaws.com/assets-us1.swirepay.com/Mobile.png' : bannerImage} width='350' style={{ margin: '10px 10px 0px 10px' }} />
                </div>
              </Col>
              {
                bannerText ? (
                  <Col span={24} className="d-flex justify-content-center align-items-center mt-2">
                    <div className="d-flex align-items-center">
                      <div className="info" style={{ lineHeight: '20px' }}>
                        <strong style={{ color: (slugName && slugName === 't20worldcup') ? '#8c16e2' : '' }}>
                          <div dangerouslySetInnerHTML={{ __html: bannerText.replaceAll('<br>', '') }} />
                        </strong>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <></>
                )
              }
              {
                slugName && slugName === 't20worldcup' && (
                  <Col span={24} className="d-flex justify-content-center align-items-center mt-2">
                    <div className="d-flex align-items-center">
                      <div className="info" style={{ lineHeight: '20px' }}>
                        <strong>Cricket Fans Unite! </strong>
                        <img src={IndFlag} alt="india" width="20" />
                        <img src={PakFlag} alt="pak" width="33" />
                      </div>
                    </div>
                  </Col>
                )
              }
              {
                slugName && slugName === 't20worldcup' && (
                  <Col span={24} className="d-flex justify-content-center align-items-center mt-2">
                    <div className="d-flex align-items-center">
                      <div className="info" style={{ lineHeight: '20px' }}>
                        <strong>Calling all cricket enthusiasts! The highly anticipated T20 World Cup match between India and Pakistan is happening on Jun 9th from 7:00 AM and it's going to be epic!</strong>
                      </div>
                    </div>
                  </Col>
                )
              }
              {
                slugName && slugName === 't20worldcup' && (
                  <Col span={24} className="d-flex justify-content-center align-items-center mt-2">
                    <div className="d-flex align-items-center">
                      <div className="info" style={{ lineHeight: '20px' }}>
                        <strong>Join the Excitement!</strong>
                      </div>
                    </div>
                  </Col>
                )
              }
              
              {
                (slugName && slugName === 'a2bplmenu') ? (
                  <>
                  </>
                ) : (
                  <>
                    {
                      enableHOP && storeOpen ? (
                        <Col span={24} className="d-flex justify-content-center align-items-center">
                          <div className="d-flex align-items-center">
                            <div className="info" style={{ lineHeight: '30px' }}>
                              <></>
                              {/* <strong>Today's Online Ordering Hours:&nbsp; {timingsInfo && timingsInfo?.length > 0 ? getOperatingTime(timingsInfo[0].operatingHours[0].openingTime) : <>&#8211;</>} to{" "}
                                {timingsInfo && timingsInfo?.length > 0 ? getOperatingTime(timingsInfo[0].operatingHours[0].closingTime) : <>&#8211;</>}</strong> */}
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <>{
                          !storeOpen ? (
                            <Col span={24} className="d-flex justify-content-center align-items-center">
                              <div className="d-flex align-items-center">
                                <div className="info" style={{ lineHeight: '30px' }}>
                                  <strong>Sorry, online orders are closed at the moment. Please check back during our opening hours.</strong>
                                </div>
                              </div>
                            </Col>
                          ) : (
                            <></>
                          )
                        }</>
                      )
                    }
                  </>
                )
              }
              
            </Row>
          ) : (
            <>
            <Row type="flex" justify="space-between" align="middle">
              <Col xs={{ span: isMobileView ? 24 : 8 }} className="d-flex justify-content-start align-items-center">
                &nbsp;
              </Col>
              <Col xs={{ span: isMobileView ? 24 : 8 }} className="d-flex justify-content-center align-items-center">
                <div className="d-flex align-items-center">
                  <img src={bannerImage} width='350' style={{ margin: '10px 10px 0px 10px' }} />
                  {/* <div className='vertical-row'></div> */}

                  {/* <div className='d-flex align-items-center'
                        style={{
                          fontSize: isMobileView ? '18px' : '24px', marginLeft: '16px', color: '#4E4E4E', fontWeight: '600'
                        }}
                      >
                        {accntName}
                      </div> */}
                </div>
                
              </Col>
              {
                !isMobileView && (
                  <Col xs={{ span: 8 }} className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center">
                    <div className='d-flex align-items-center'
                    style={{
                      fontSize: '18px', cursor: 'pointer', marginLeft: '16px', color: '#4E4E4E', fontWeight: '600'
                    }}
                  >
                    <Search placeholder="Search store menu" style={{borderRadius:'34px !important'}} value={searchValue} allowClear onChange={(e) => searchItems(e.currentTarget.value)}/>
                  </div>
                      {/* {
                        userInfo && userInfo.reservationCode && (
                          <div className='d-flex align-items-center'
                            style={{
                              fontSize: '18px', cursor: 'pointer', marginLeft: '16px', color: '#4E4E4E', fontWeight: '600'
                            }}
                          >
                            <SpText fontSize="16px">Group Id</SpText>
                            <div style={{ marginLeft: '10px' }}>
                              <SpText style={{ backgroundColor: '#F2F2F2', padding: '5px', borderRadius: '5px' }} fontSize="16px" color="#00378C" fontWeight="700">{userInfo.reservationCode}</SpText>
                            </div>
                          </div>
                        )
                      } */}

                     
                      {/* <div className='vertical-row'></div>
              <div className='d-flex align-items-center'
                  style={{
                    fontSize: '18px', cursor: 'pointer', marginLeft: '16px', color: '#4E4E4E', fontWeight: '600'
                  }}
                >
                  Sign In
                </div> */}
                    </div>
                  </Col>
                )
              }

            </Row>
            {
                  bannerText ? (
                    <Row type="flex" justify="space-between" align="middle">
                      <Col span={24} className="d-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="info" style={{ lineHeight: '30px' }}>
                            <span style={{ color: (slugName && slugName === 't20worldcshaup') ? '#8c16e2' : '' }}>
                              <div dangerouslySetInnerHTML={{ __html: bannerText.replaceAll('<br>', '') }} />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
                {
                  slugName && slugName === 't20worldcup' ? (
                    <Row type="flex" justify="space-between" align="middle">
                      <Col span={24} className="d-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="info" style={{ lineHeight: '30px' }}>
                          <strong>Cricket Fans Unite! </strong>
                          <img src={IndFlag} alt="india" width="20" />
                          <img src={PakFlag} alt="pak" width="33" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
                {
                  slugName && slugName === 't20worldcup' ? (
                    <Row type="flex" justify="space-between" align="middle">
                      <Col span={24} className="d-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="info" style={{ lineHeight: '30px' }}>
                          <strong>Calling all cricket enthusiasts! The highly anticipated T20 World Cup match between India and Pakistan is happening on Jun 9th from 7:00 AM and it's going to be epic!</strong>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
            {
                  slugName && slugName === 't20worldcup' ? (
                    <Row type="flex" justify="space-between" align="middle">
                      <Col span={24} className="d-flex justify-content-center align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="info" style={{ lineHeight: '30px' }}>
                            <strong>Join the Excitement!</strong>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
                
                {
                  slugName && slugName === 'a2bplmenu' ? (
                    <></>
                  ) : (
                    <>
                      {
                        enableHOP && storeOpen ? (
                          <Row type="flex" justify="space-between" align="middle">
                            <Col span={24} className="d-flex justify-content-center align-items-center">
                              <div className="d-flex align-items-center">
                                <div className="info" style={{ lineHeight: '30px' }}>
                                  <></>
                                  {/* <strong>Today's Online Ordering Hours:&nbsp; {timingsInfo && timingsInfo?.length > 0 ? getOperatingTime(timingsInfo[0].operatingHours[0].openingTime) : <>&#8211;</>} to{" "}
                                    {timingsInfo && timingsInfo?.length > 0 ? getOperatingTime(timingsInfo[0].operatingHours[0].closingTime) : <>&#8211;</>}</strong> */}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <>{
                            !storeOpen ? (
                              <Row type="flex" justify="space-between" align="middle">
                                <Col span={24} className="d-flex justify-content-center align-items-center">
                                  <div className="d-flex align-items-center">
                                    <div className="info" style={{ lineHeight: '30px' }}>
                                      <strong>Sorry, online orders are closed at the moment. Please check back during our opening hours.</strong>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            ) : (
                              <></>
                            )
                          }</>
                        )
                      }
                    </>
                  )
                }
            </>
          )
        }
      </Header>
    </>
  );
};

const mapStateToProps = (state) => ({
  itemsInfos: state.cartInfo.itemsInfos,
  isMobileView: state.cartInfo.isMobileView,
  shopInfo: state.cartInfo.shopInfo,
  searchItem: state.cartInfo.searchItems,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  getCartVisible: param => dispatch(cartActions.getCartVisible({
    payload: param,
  })),
  getSearchItems: param => dispatch(cartActions.getSearchItems({
    payload: param,
  })),
  setSearchItems: param => dispatch(cartActions.setSearchItems({
    payload: param,
  })),
  setShopOpen: param => dispatch(cartActions.setShopOpen({
    payload: param,
  })),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppHeader),
);
