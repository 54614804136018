import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

import ProtectedRoute from "./components/ProtectedRoute";
import BasePage from "./components/BasePage";
import routes from "./config/routes";
import { PriceQuantityProvider } from "./context/PriceQuantityContext";

const GlobalStyle = createGlobalStyle`
  html {
  };
  body {
    font-family: Open Sans;
    font-size: 14px;
  }
`;

const App = () => (
  <BasePage>
    <GlobalStyle />
    <PriceQuantityProvider>
      <Switch>
        {routes.map((route) => {
          if (route.protected) {
            return (
              <ProtectedRoute
                key={route.key}
                path={route.path}
                exact={route.exact}
                proteced={route.protected}
                component={route.component}
              />
            );
          }
          return (
            <Route
              key={route.key}
              path={route.path}
              exact={route.exact}
              proteced={route.protected}
              render={(props) => <route.component {...props} />}
            />
          );
        })}
      </Switch>
    </PriceQuantityProvider>
  </BasePage>
);

export default withRouter(App);
