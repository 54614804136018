import { combineReducers } from "redux";

import {
  PAGES,
  TOKENS,
} from '../../appconstants';

import notification from './notification';
import loading from './loading';
import { AUTH } from '../actionTypes';
import cartInfo from './cartActions';

const appReducer = combineReducers({
    notification,
    loading,
    cartInfo,
});

const rootReducer = (state, action) => {
    if (action.type === AUTH.LOGOUT) {
        localStorage.removeItem(TOKENS.ACCESS_TOKEN);
        localStorage.removeItem(TOKENS.REFRESH_TOKEN);
        localStorage.removeItem('email');
        localStorage.removeItem(TOKENS.REFERRAL_CODE);
        localStorage.removeItem(TOKENS.AFFILIATE_CODE);
        localStorage.removeItem('dynamicStaticQr');
        // eslint-disable-next-line no-param-reassign
        state = undefined;
        window.location.assign(PAGES.MEMBERSHIP);
      } else if (action.type === AUTH.UNLOCK) {
        localStorage.removeItem(TOKENS.ACCESS_TOKEN);
        localStorage.removeItem(TOKENS.REFRESH_TOKEN);
        localStorage.removeItem(TOKENS.REFERRAL_CODE);
        localStorage.removeItem(TOKENS.AFFILIATE_CODE);
        localStorage.removeItem('dynamicStaticQr');
        // eslint-disable-next-line no-param-reassign
        state = undefined;
      }
      return appReducer(state, action);
}

export default rootReducer;
