// @flow
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
} from 'antd';
import styled from 'styled-components';
import SimpleReactValidator from 'simple-react-validator';
import type { ContextRouter } from 'react-router-dom';

import Loading from '../../components/Loading';

import {
  notificationActions,
} from '../../store/actions';

// $FlowFixMe
const Wrapper = styled.div`
  background-repeat: no-repeat;
  background-position: right;
  background-attachment: fixed;
  background-size: 17%;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #111110;
`;

type Props = ContextRouter & {
  history: {
    push: Function,
  },
  children: Node,
  error: boolean,
  loading: boolean,
  setNotification: Function,
};

const Orders = (props: Props) => {
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(false);


  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  if (loading) {
    return <Loading />;
  }

  return (
    <Wrapper>
      <Row style={{ overflowX: 'hidden' }}>
        <Col span={24} className='py-3 px-3 membership-navbar' style={{ background: '#111110', }}>
          <img src='https://assets.taste.club/img/membership_form/loginLogo.svg' alt='logo' height='37' width='180' />
        </Col>
        <Col span={24} className='p-md-5 p-4' style={{ background: 'url(https://assets.taste.club/img/membership_form/membershipNewBg.svg)', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
          <div className='d-none d-md-block'>
            <a href='https://www.taste.club/' target='_blank'><img src='https://assets.taste.club/img/membership_form/loginLogo.svg' alt='logo' /></a>
          </div>
          <div className='d-flex w-100 justify-content-center align-items-center my-xl-5 my-4 py-xl-5 py-4'>
            <span className='py-xl-5 my-xl-5 d-md-flex align-items-center justify-content-center herosection-title'>Join the <img src='https://assets.taste.club/img/membership_form/clubLogo.svg' alt='club logo' className='px-md-4 px-1' /></span>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  // loading: state.loading.loading,
  error: state.loading.error,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Orders);
