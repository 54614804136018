// import {
//   dashboard,
// } from '../components/NavIcons';

import { ROLES } from '../appconstants';

const NAVBAR_OPTIONS = [
  {
    key: '/users',
    icon: '',
    display: 'Users',
    width: 25,
    height: 22,
    allowedRoles: [],
  },
].filter(route => !!route);

// eslint-disable-next-line array-callback-return,consistent-return
export default NAVBAR_OPTIONS;
