// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Input,
    Select,
    Modal,
    Row,
    Col,
    Button,
    Checkbox,
    Radio,
} from 'antd';
import {
    cartActions,
  } from '../../../store/actions';
import { SpText } from '../../../components/DesignKit';


type Props = {
    close: Function,
    submit: Function,
    visible: Boolean,
    shopInfo: Array<Object>,
    setSelectedShopInfo: Function,
    isMobileView: Boolean,
};

const ShopSelection = (props: Props) => {
    const {
        visible,
        close,
        submit,
        shopInfo,
        setSelectedShopInfo,
        isMobileView,
    } = props;

    const requestQuickSupport = (item) => {
        localStorage.setItem('selectedShop', item.gid);
        setSelectedShopInfo(item);
        close();
      };

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            footer={null}
            className={isMobileView ? "cartModal1 cartModalShopMbl" : 'cartModalShop'}
        >
            <div className="px-4">
                <div className="mb-3 mt-2" style={{ textAlign: 'center' }}>
                    <SpText fontSize="20px" color='#000000' fontWeight='600'>Select Store</SpText>
                </div>
                {
                   shopInfo && shopInfo.length !== 0 && (
                    <>
                    {
                        shopInfo.map((item) => (
                            <div
                                className="mb-3 mt-2"
                                style={{ border: '2px solid #FFFFFF', borderRadius: '10px', padding: '5px 10px', cursor: 'pointer', backgroundColor: '#FFFFFF' }}
                                onClick={() => requestQuickSupport(item)}
                            >
                                <SpText fontSize="16px" color='#292929' fontWeight='600'>{item.name}</SpText>
                            </div>
                        ))
                    }
                    </>
                   ) 
                }
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    shopInfo: state.cartInfo.shopInfo,
    isMobileView: state.cartInfo.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
    setSelectedShopInfoTemp: param => dispatch(cartActions.setSelectedShopInfoTemp({
      payload: param,
    })),
    setSelectedShopInfo: param => dispatch(cartActions.setSelectedShopInfo({
      payload: param,
    })),
  });

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ShopSelection);
