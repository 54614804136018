import React, { useEffect, useState } from "react";
import { Row, Col, Button, Table } from "antd";
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';
// import { getInventoryOrder } from "../../api";
import { freshText } from "../../assets/imgs/images";
import {
  SpText,
  SpH5,
  SpButton,
  SpHead,
  SpH6,
  Line,
  CardSchemeIcon,
} from '../../components/DesignKit';
import {
  axiosNoAuthInstance,
  axiosAuthInstance,
  API_END_POINTS,
} from '../../api';

type Props = {
  history: {
    push: Function,
  },
  isMobileView: Boolean,
};

const Wrapper = styled.div`
    background-color: #f7f7f7;
    width: 100%;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `;


const Summary = (props: Props) => {
  const {
    history,
    isMobileView,
  } = props;
  const { id } = props.match.params;
  const [orderLineItems, setOrderLineItems] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [bannerImg, setBannerImg] = useState('');
  const [selectedAccount, setSelectedAccount] = useState();
  const location = useLocation();
  const { pathname } = location;
  const slugName = pathname.replace("/", "");
  const publicKey = localStorage.getItem('publicKey');

  const FormWrapper = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '34px',
    paddingRight: '34px',
    width: isMobileView ? '90%' : '400px',
    minHeight: '520px',
    borderRadius: '10px',
    boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#FFF',
  });

  const getAccountDetails = async (slug) => {
    try {
      const { data: { entity } } = await axiosNoAuthInstance.get(`${API_END_POINTS.GET_PUBLICK_KEY}/${slug}`);
      localStorage.setItem('publicKey', entity.publicKey);
      localStorage.setItem('shopGid', entity.gid);
      localStorage.setItem('accountName', entity.name);
      setBannerImg(entity.banner ? entity.banner : entity.largeLogo);
      setSelectedAccount(entity.account);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const slug = slugName.split('/')[1];
    if (slug) {
      getAccountDetails(slug);
    } 
  }, [slugName]);

  const joinReservation = async (inventGid) => {
      try {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ORDER}/${inventGid}`);
        setOrderDetails(entity);
      } catch (e) {
        console.error(e);
      }
  };

  useEffect(() => {
    const slug = slugName.split('/')[1];
    const parsed = queryString.parse(location.search);
    const inventId = parsed.inventId;
    if (inventId && publicKey) {
      joinReservation(inventId);
    }
  }, [location, publicKey]);

  const redirectToHome = () => {
    window.location = orderDetails && orderDetails.inventoryOrderLink.redirectUrl;
  };

  const getPaymentDate = (updatedAt) => {
    if (selectedAccount && selectedAccount.timezone) {
     return moment.utc(updatedAt).tz(selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a')
    }
  };

  return (
    <Wrapper>
                <FormWrapper>
                <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                      <img src={bannerImg} width={isMobileView ? "100" : "100"} />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                      <SpText fontSize="22px" color="#000000" fontWeight="600">Order# {orderDetails && orderDetails.orderNumber}</SpText>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                      <SpText fontSize="18px" color="#757575" fontWeight="400">{orderDetails && getPaymentDate(orderDetails.updatedAt)}</SpText>
                    </Col>
                  </Row>
                  <Row type="flex">
                    <Col type="flex" align="middle" className="justify-content-center mb-2">
                      <SpHead fontWeight="600">
                      Bill details
                      </SpHead>
                    </Col>
                  </Row>
                  {
                            orderDetails && orderDetails.inventoryOrderLineItems && orderDetails.inventoryOrderLineItems.map((orders) => (
                              <>
                                <Row type="flex" justify="space-between">
                                  <Col span={12} className="justify-content-center align-items-center mb-2">
                                    <div>
                                      <SpText fontSize="16px" color="#5C5C5C" fontWeight="700">{orders.itemName}</SpText>
                                    </div>
                                    
                                    <div>
                                      <SpText fontSize="16px" color="#5C5C5C" fontWeight="400">$ {(orders.amount / 100).toFixed(2)}</SpText>
                                    </div>
                                  </Col>
                                  <Col span={4} className="justify-content-center align-items-center mb-2">
                                        <SpText fontSize="18px" color='#5C5C5C' fontWeight='500' style={{ padding: '0px 10px' }}>x{orders.quantity}</SpText>
                                      </Col>
                                  <Col span={8} className="justify-content-center align-items-end mb-2">
                                    <SpText fontSize="16px" color="#5C5C5C" fontWeight="700">$ {((orders.quantity * orders.amount) / 100).toFixed(2)}</SpText>
                                  </Col>
                                </Row>
                                {
                                  orders.lineItemModifiers.map((item) => (
                                    <Row type="flex" justify="space-between">
                                  <Col span={12} className="justify-content-center align-items-center mb-2">
                                    <div>
                                      <SpText fontSize="16px" color="#5C5C5C" fontWeight="400">{item.modifierName}</SpText>
                                    </div>
                                  </Col>
                                  <Col span={4} className="justify-content-center align-items-center mb-2">
                                        <SpText fontSize="16px" color="#5C5C5C" fontWeight="400" style={{ padding: '0px 10px' }}>x{item.quantity}</SpText>
                                      </Col>
                                  <Col span={8} className="justify-content-center align-items-end mb-2">
                                    <SpText fontSize="16px" color="#5C5C5C" fontWeight="400">$ {((item.quantity * item.price) / 100).toFixed(2)}</SpText>
                                  </Col>
                                </Row>
                                  ))
                                }
                                {
                                  orders.discountAmount ? (
                                    <Row type="flex" justify="space-between">
                                  <Col span={12} className="justify-content-center align-items-center mb-2">
                                    <div>
                                      <SpText fontSize="16px" color="#FF0000" fontWeight="400">Discount</SpText>
                                    </div>
                                  </Col>
                                  <Col span={4} className="justify-content-center align-items-center mb-2">
                                        <SpText fontSize="16px" color="#5C5C5C" fontWeight="400" style={{ padding: '0px 10px' }}>&nbsp;</SpText>
                                      </Col>
                                  <Col span={8} className="justify-content-center align-items-end mb-2">
                                    <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {((orders.quantity * orders.discountAmount) / 100).toFixed(2)}</SpText>
                                  </Col>
                                </Row>
                                  ) : (
                                    <></>
                                  )
                                }
                                <hr />
                              </>
                            ))
                          }
                  <Row type="flex" justify="space-between">
                    <Col span={12} className="justify-content-center align-items-center mb-2">
                      <SpText fontSize="16px" color="#000000" fontWeight="400">Sub Total</SpText>
                    </Col>
                    <Col span={12} className="justify-content-end align-items-center mb-2" style={{ display: 'inline-grid' }}>
                    <SpText fontSize="16px" color="#000000" fontWeight="400">$ {orderDetails && (orderDetails.subTotal / 100).toFixed(2)}</SpText>
                    </Col>
                  </Row>
                  {
                    orderDetails && orderDetails.orderDiscountAmount ? (
                  <Row type="flex" justify="space-between">
                    <Col span={12} className="justify-content-center align-items-center mb-2">
                      <SpText fontSize="16px" color="#000000" fontWeight="400">Discount</SpText>
                    </Col>
                    <Col span={12} className="justify-content-end align-items-center mb-2" style={{ display: 'inline-grid' }}>
                    <SpText fontSize="16px" color="#FF0000" fontWeight="400"> $ {orderDetails && (orderDetails.orderDiscountAmount / 100).toFixed(2)}</SpText>
                    </Col>
                  </Row>
                    ) : (
                      <></>
                    )
                  }
                  
                  {
                    orderDetails && orderDetails.totalTax ? (
                      <Row type="flex" justify="space-between">
                    <Col span={12} className="justify-content-center align-items-center mb-2">
                      <SpText fontSize="16px" color="#000000" fontWeight="400">Tax</SpText>
                    </Col>
                    <Col span={12} className="justify-content-end align-items-center mb-2" style={{ display: 'inline-grid' }}>
                    <SpText fontSize="16px" color="#000000" fontWeight="400">$ {orderDetails && (orderDetails.totalTax / 100).toFixed(2)}</SpText>
                    </Col>
                  </Row>
                    ) : (
                      <></>
                    )
                  }
                   {
                    orderDetails && orderDetails.totalServiceFee ? (
                      <Row type="flex" justify="space-between">
                    <Col span={12} className="justify-content-center align-items-center mb-2">
                      <SpText fontSize="16px" color="#000000" fontWeight="400">Service Charge</SpText>
                    </Col>
                    <Col span={12} className="justify-content-end align-items-center mb-2" style={{ display: 'inline-grid' }}>
                    <SpText fontSize="16px" color="#000000" fontWeight="400">$ {orderDetails && (orderDetails.totalServiceFee / 100).toFixed(2)}</SpText>
                    </Col>
                  </Row>
                    ) : (
                      <></>
                    )
                  }
        {
          orderDetails && orderDetails.totalTip ? (
            <Row type="flex" justify="space-between">
              <Col span={12} className="justify-content-center align-items-center mb-2">
                <SpText fontSize="16px" color="#000000" fontWeight="400">Tip</SpText>
              </Col>
              <Col span={12} className="justify-content-end align-items-center mb-2" style={{ display: 'inline-grid' }}>
                <SpText fontSize="16px" color="#000000" fontWeight="400">$ {orderDetails && (orderDetails.totalTip / 100).toFixed(2)}</SpText>
              </Col>
            </Row>
          ) : (
            <></>
          )
        }
                  
                  
                  <hr />
                  <Row type="flex" justify="space-between">
                    <Col span={12} className="justify-content-center align-items-center mb-2">
                      <SpText fontSize="16px" color="#000000" fontWeight="600">Order Total</SpText>
                    </Col>
                    <Col span={12} className="justify-content-end align-items-center mb-2" style={{ display: 'inline-grid' }}>
                    <SpText fontSize="16px" color="#000000" fontWeight="400">$ {orderDetails && (orderDetails.total / 100).toFixed(2)}</SpText>
                    </Col>
                  </Row>
                  <hr />
                  <Row type="flex">
                    <Col type="flex" align="middle" className="justify-content-center">
                      <SpHead fontWeight="600">
                      Payment method
                      </SpHead>
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={12} className="justify-content-center align-items-center mb-2">
                      <SpText fontSize="16px" color="#000000" fontWeight="600">{orderDetails && orderDetails.paymentSession ? orderDetails.paymentSession.method : <>&#8211;</>}</SpText>
                    </Col>
                    <Col span={12} className="justify-content-end align-items-center mb-2" style={{ display: 'inline-grid' }}>
                    <SpText fontSize="16px" color="#000000" fontWeight="400">$ {orderDetails && (orderDetails.total / 100).toFixed(2)}</SpText>
                    </Col>
                  </Row>
                  
                  <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex justify-content-center align-items-center mb-2 mt-3" style={{ cursor: 'pointer' }} onClick={() => redirectToHome()}>
                      <SpText fontSize="22px" color="#00378C" fontWeight="600">Back to Online Shop</SpText>
                    </Col>
                  </Row>
                </FormWrapper>
              </Wrapper>
  );
}

const mapStateToProps = (state) => ({
  isCartVisible: state.cartInfo.isCartVisible,
  shopInfo: state.cartInfo.shopInfo,
  customerInfo: state.cartInfo.customerInfo,
  itemsInfos: state.cartInfo.itemsInfos,
  isMobileView: state.cartInfo.isMobileView,
  inVentData: state.cartInfo.inVentData,
  isProfileView: state.cartInfo.isProfileView,
  userInfo: state.cartInfo.userInfo,
  quickSupport: state.cartInfo.quickSupport,
});

const mapDispatchToProps = (dispatch) => ({
  getItemsInfo: param => dispatch(cartActions.getItemsInfo({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
