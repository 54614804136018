export const LOADING = {
    SET: 'SET_LOADING',
    UNSET: 'UNSET_LOADING',
    SUBMITTING: 'SET_SUBMITTING',
    SUBMITTED: 'SET_SUBMITTED',
    VALIDATING: 'SET_VALIDATING',
    VALIDATED: 'SET_VALIDATED',
    SUCCESS: 'LOADING_SUCCESS',
    ERROR: 'LOADING_ERROR',
    RESET: 'LOADING_RESET',
  };
  
  export const NOTIFICATION = {
    SUCCESS: 'NOTIFICATION_SUCCESS',
    ERROR: 'NOTIFICATION_ERROR',
    RESET: 'NOTIFICATION_RESET',
    THANKYOU: 'NOTIFICATION_THANKYOU',
  };

/* AUTH USER ACTIONS */
const AUTH_USER = 'AUTH_USER';
export const AUTH = {
  AUTHENTICATE: `${AUTH_USER}_AUTHENTICATE`,
  SUCCESS: `${AUTH_USER}_SUCCESS`,
  FAILED: `${AUTH_USER}_FAILED`,
  LOGOUT: 'LOGOUT',
  UNLOCK: 'UNLOCK',
};

/* AUTH USER ACTIONS */
const CART_ITEMS = 'CART_ITEMS';
export const CART = {
  SET_VISIBLE: `${CART_ITEMS}_SET_VISIBLE`,
  SHOP_INFO: `${CART_ITEMS}_SHOP_INFO`,
  CUSTOMER_INFO: `${CART_ITEMS}_CUSTOMER_INFO`,
  ITEMS_INFO: `${CART_ITEMS}_ITEMS_INFO`,
  GET_MOBILE_VIEW: `${CART_ITEMS}_GET_MOBILE_VIEW`,
  GEI_INVENTORY_ORDER: `${CART_ITEMS}_GEI_INVENTORY_ORDER`,
  USER_PROFILE: `${CART_ITEMS}_USER_PROFILE`,
  QUICK_SUPPORT: `${CART_ITEMS}_QUICK_SUPPORT`,
  SEARCH_ITEMS: `${CART_ITEMS}_SEARCH_ITEMS`,
  SEARCH_SET: `${CART_ITEMS}_SEARCH_SET`,
  SELECTED_SHOP_INFO: `${CART_ITEMS}_SELECTED_SHOP_INFO`,
  SELECTED_SHOP_INFO_TEMP: `${CART_ITEMS}_SELECTED_SHOP_INFO_TEMP`,
  SHOP_OPEN: `${CART_ITEMS}_SHOP_OPEN`,
};