import React from "react";

export default function index() {
  return (
    <div className="main-header">
      <div className="container">
        <div>
          <span className="logo_helper" />
          <a href="/onlineOrders">
            <img
              src="	https://cdn.smartonlineorder.com/wp-content/uploads/sites/1658/2020/04/866ab91252a97eff.png"
              className="logo"
            />
          </a>
        </div>
        <div className="et-top-navigation">
          <nav id="top-menu-nav">
            <ul id="top-menu" class="nav">
              <li>
                <a href="/onlineOrders">Order Online</a>
              </li>
              <li>
                <a href="/cart">Cart</a>
              </li>
              <li>
                <a href="/checkout">Checkout</a>
              </li>
              <li>
                <a href="/myOrders">My Orders</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
