// @flow
import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { withRouter, useLocation, matchPath } from 'react-router-dom';
import styled from 'styled-components';
import type { Node } from 'react';
import type { ContextRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SideNav from '../SideNav';
import Header from '../Header';

import Notification from '../Notification';
import { notificationActions, cartActions } from '../../store/actions';
import { NOTIFICATION } from '../../store/actionTypes';
import { PAGES } from '../../appconstants';



const { Content } = Layout;

type Props = ContextRouter & {
  setTestData: Function,
  resetNotification: Function,
  history: {
    push: Function,
  },
  children: Node,
  error: boolean,
  success: boolean,
  thankyou: boolean,
  getMobileView: Function,
};

const BasePage = (props: Props) => {
  const {
    history,
    children,
    error,
    success,
    thankyou,
    resetNotification,
    getMobileView,
  } = props;
  
  const HIDE_CONTENT_ROUTES = [
    `${PAGES.RECEIPT}/:slugName`,
    `${PAGES.INAVALID_SLUG}`,
  ];

  const { pathname } = useLocation();

  // $FlowFixMe
  const AppLayout = styled(Layout)({
  });

  // $FlowFixMe
  const AppContent = styled(Content)({
    overflowY: 'hidden',
    overflowX: 'hidden',
  });

  useEffect(() => {
    //screen.orientation.lock();
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      getMobileView(true);
    } else if (windowWidth > 480) {
      getMobileView(false);
    }
  }, []);

  const onResize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      getMobileView(true);
    } else if (windowWidth > 480) {
      getMobileView(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);


  useEffect(() => {
    resetNotification();
  }, []);

  useEffect(() => {
    resetNotification();
  }, []);

  // useEffect(() => {
  //   localStorage.removeItem('shopTable');
  //   localStorage.removeItem('selectedShop');
  //   localStorage.removeItem('redirectUrl');
  //   localStorage.removeItem('publicKey');
  // }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  const showHeader = !HIDE_CONTENT_ROUTES.find((route) => matchPath(history.location.pathname,
    {
      path: route,
      exact: true,
      strict: false,
    }));

  return (
    <HelmetProvider>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      {
        showHeader ? (
          <AppLayout>
            {/* <SideNav /> */}
            <Layout>
              <Header />
              <AppContent className='px-2'>
                {
                  (Boolean(error) || Boolean(success)) && (
                    <Notification />
                  )
                }
                {children}
              </AppContent>
            </Layout>
          </AppLayout>
        ) : (
          <AppLayout>
            <Layout>
              <AppContent className='px-2'>
                {
                  (Boolean(error) || Boolean(success)) && (
                    <Notification />
                  )
                }
                {children}
              </AppContent>
            </Layout>
          </AppLayout>
        )
      }

    </HelmetProvider>
  );
};

const mapStateToProps = (state) => ({
  success: state.notification.success,
  error: state.notification.error,
  thankyou: state.notification.thankyou,
});

const mapDispatchToProps = (dispatch) => ({
  resetNotification: () => dispatch(notificationActions.resetNotification({
    type: NOTIFICATION.RESET,
  })),
  getMobileView: param => dispatch(cartActions.getMobileView({
    payload: param,
  })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BasePage));
