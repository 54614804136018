import { NOTIFICATION } from '../actionTypes';

const initialState = {
  error: '',
  success: '',
  thankyou: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION.SUCCESS:
      return {
        ...state,
        error: '',
        thankyou: '',
        success: action.payload,
      };
    case NOTIFICATION.ERROR:
      return {
        ...state,
        success: '',
        thankyou: '',
        error: action.payload,
      };
    case NOTIFICATION.THANKYOU:
      return {
        ...state,
        error: '',
        success: '',
        thankyou: action.payload,
      };
    case NOTIFICATION.RESET:
      return {
        ...state,
        error: '',
        success: '',
        thankyou: '',
      };
    default:
      return state;
  }
};
