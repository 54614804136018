// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Input,
    Select,
    Modal,
    Row,
    Col,
    Button,
    Checkbox,
    Radio,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
    axiosAuthInstance,
    API_END_POINTS,
} from '../../../api';
import { SpH5, SpError, SpButton, SpText } from '../../../components/DesignKit';
import CloseIcon from "../../../assets/imgs/close.svg";

const { Option } = Select;
const { TextArea } = Input;

type Props = {
    close: Function,
    submit: Function,
    visible: Boolean,
    selectedItem: Object,
    shopGid: String,
};

const AddItems = (props: Props) => {
    const {
        visible,
        close,
        submit,
        selectedItem,
        shopGid,
    } = props;

    const [quantity, setQuantity] = useState(1);
    const [modifierGroups, setModifierGroups] = useState([]);
    const [notes, setNotes] = useState(null);
    const [validation, setValidation] = useState(false);
    const [splitUpInfo, setSplitUpInfo] = useState(null);

    const getSplitUpInfo = async (itemsInfos) => {
        const params = {
            shopGid: shopGid,
            items: itemsInfos,
        }
        try {
            const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.GET_SPLIT_UP, params);
            setSplitUpInfo(entity);
        } catch (e) {
            console.error(e);
        }
    };

    const reduceQuant = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
            const lineItems = modifierGroups.filter((item) => item.quantity && item.quantity > 0);
            const lineItemModifiers = [];
            let tempObject = {};
            for (let i = 0; i < lineItems.length; i += 1) {
                tempObject.quantity = lineItems[i].quantity;
                tempObject.modifierGid = lineItems[i].gid;
                tempObject.price = lineItems[i].price;
                tempObject.name = lineItems[i].name;
                if (lineItems[i].maxStackableQuantity === 1) {
                    tempObject.isStackable = true;
                } else {
                    tempObject.isStackable = false;
                }
                lineItemModifiers.push(tempObject);
                tempObject = {};
            }
            const item = {
                quantity: quantity - 1,
                itemGid: selectedItem.gid,
                price: selectedItem.price,
                name: selectedItem.name,
                isExst: false,
                lineItemModifiers,
                note: notes,
                discountedPrice: selectedItem.discountedPrice,
                coupon: selectedItem.coupon ? selectedItem.coupon : null,
            };
            getSplitUpInfo([item]);
        };
    }

    const incrseQuant = () => {
        setQuantity(quantity + 1);
        const lineItems = modifierGroups.filter((item) => item.quantity && item.quantity > 0);
        const lineItemModifiers = [];
        let tempObject = {};
        for (let i = 0; i < lineItems.length; i += 1) {
            tempObject.quantity = lineItems[i].quantity;
            tempObject.modifierGid = lineItems[i].gid;
            tempObject.price = lineItems[i].price;
            tempObject.name = lineItems[i].name;
            if (lineItems[i].maxStackableQuantity === 1) {
                tempObject.isStackable = true;
            } else {
                tempObject.isStackable = false;
            }
            lineItemModifiers.push(tempObject);
            tempObject = {};
          } 
        const item = {
            quantity: quantity + 1,
            itemGid: selectedItem.gid,
            price: selectedItem.price,
            name: selectedItem.name,
            isExst: false,
            lineItemModifiers,
            note: notes,
            discountedPrice: selectedItem.discountedPrice,
            coupon: selectedItem.coupon ? selectedItem.coupon : null,
        };
        getSplitUpInfo([item]);
    }

    const incrseModifierQuant = (obj, index, i) => {
        setValidation(false);
        if (selectedItem.modifierGroups[index].quantity) {
            selectedItem.modifierGroups[index].quantity = selectedItem.modifierGroups[index].quantity + 1;
        } else {
            selectedItem.modifierGroups[index].quantity = 1;
        }
        const lineItems = [...modifierGroups];
        const isExistItem = lineItems.filter((item) => item.gid === obj.gid);
        if (isExistItem && isExistItem.length !== 0) {
            const index = lineItems.findIndex(p => p.gid === obj.gid);
            lineItems[index].quantity = lineItems[index].quantity + 1;
        } else {
            obj.quantity = 1;
            lineItems.push(obj);
        }
        setModifierGroups(lineItems);
        const lineItemsTemp = lineItems.filter((item) => item.quantity && item.quantity > 0);
        const lineItemModifiers = [];
        let tempObject = {};
        for (let i = 0; i < lineItemsTemp.length; i += 1) {
            tempObject.quantity = lineItemsTemp[i].quantity;
            tempObject.modifierGid = lineItemsTemp[i].gid;
            tempObject.price = lineItemsTemp[i].price;
            tempObject.name = lineItemsTemp[i].name;
            if (lineItems[i].maxStackableQuantity === 1) {
                tempObject.isStackable = true;
            } else {
                tempObject.isStackable = false;
            }
            lineItemModifiers.push(tempObject);
            tempObject = {};
          } 
        const item = {
            quantity: quantity,
            itemGid: selectedItem.gid,
            price: selectedItem.price,
            name: selectedItem.name,
            isExst: false,
            lineItemModifiers,
            note: notes,
            discountedPrice: selectedItem.discountedPrice,
            coupon: selectedItem.coupon ? selectedItem.coupon : null,
        };
        getSplitUpInfo([item]);
    }

    const reduceModifierQuant = (obj, index, i) => {
        setValidation(false);
        if (selectedItem.modifierGroups[index].quantity && selectedItem.modifierGroups[index].quantity > 0 && obj.quantity > 0) {
            selectedItem.modifierGroups[index].quantity = selectedItem.modifierGroups[index].quantity - 1;
        }
        const lineItems = [...modifierGroups];
        const isExistItem = lineItems.filter((item) => item.gid === obj.gid);
        if (isExistItem && isExistItem.length !== 0) {
            const index = lineItems.findIndex(p => p.gid === obj.gid);
            if (lineItems[index].quantity > 1) {
                lineItems[index].quantity = lineItems[index].quantity - 1;
            } else if (lineItems[index].quantity === 1) {
                lineItems[index].quantity = 0;
            }  
        }
        setModifierGroups(lineItems);
        const lineItemsTemp = lineItems.filter((item) => item.quantity && item.quantity > 0);
        const lineItemModifiers = [];
        let tempObject = {};
        for (let i = 0; i < lineItemsTemp.length; i += 1) {
            tempObject.quantity = lineItemsTemp[i].quantity;
            tempObject.modifierGid = lineItemsTemp[i].gid;
            tempObject.price = lineItemsTemp[i].price;
            tempObject.name = lineItemsTemp[i].name;
            if (lineItems[i].maxStackableQuantity === 1) {
                tempObject.isStackable = true;
            } else {
                tempObject.isStackable = false;
            }
            lineItemModifiers.push(tempObject);
            tempObject = {};
          } 
        const item = {
            quantity: quantity,
            itemGid: selectedItem.gid,
            price: selectedItem.price,
            name: selectedItem.name,
            isExst: false,
            lineItemModifiers,
            note: notes,
            discountedPrice: selectedItem.discountedPrice,
            coupon: selectedItem.coupon ? selectedItem.coupon : null,
        };
        getSplitUpInfo([item]); 
    }

    const stackModifier = (checked, obj, array, index, i) => {
        setValidation(false);
        for (let j = 0; j < selectedItem.modifierGroups[index].modifiers.length; j += 1) {
            selectedItem.modifierGroups[index].modifiers[j].quantity = 0;
        }
        const lineItems = [...modifierGroups];
        const isExistItem = lineItems.filter((item) => item.gid === obj.gid);
        for (let k = 0; k < lineItems.length; k += 1) { 
            for (let l = 0; l < array.length; l += 1) { 
                if (lineItems[k].gid === array[l].name) {
                    lineItems.splice(k, 1);
                }
            }
        }
        if (isExistItem && isExistItem.length !== 0) {
            const inde = lineItems.findIndex(p => p.gid === obj.gid);
            if (checked) {
                lineItems[inde].quantity = 1;
            } else {
                lineItems.splice(inde, 1);
            }  
        } else {
            if (checked) {
                obj.quantity = 1;
            }
            lineItems.push(obj);
        }
        setModifierGroups(lineItems);
        const lineItemsTemp = lineItems.filter((item) => item.quantity && item.quantity > 0);
        const lineItemModifiers = [];
        let tempObject = {};
        for (let i = 0; i < lineItemsTemp.length; i += 1) {
            tempObject.quantity = lineItemsTemp[i].quantity;
            tempObject.modifierGid = lineItemsTemp[i].gid;
            tempObject.price = lineItemsTemp[i].price;
            tempObject.name = lineItemsTemp[i].name;
            if (lineItems[i].maxStackableQuantity === 1) {
                tempObject.isStackable = true;
            } else {
                tempObject.isStackable = false;
            }
            lineItemModifiers.push(tempObject);
            tempObject = {};
          } 
        const item = {
            quantity: quantity,
            itemGid: selectedItem.gid,
            price: selectedItem.price,
            name: selectedItem.name,
            isExst: false,
            lineItemModifiers,
            note: notes,
            discountedPrice: selectedItem.discountedPrice,
            coupon: selectedItem.coupon ? selectedItem.coupon : null,
        };
        getSplitUpInfo([item]);
    };

    const validate = () => {
        const lineItems = modifierGroups.filter((item) => item.quantity && item.quantity > 0);
        if (selectedItem.modifierGroups && selectedItem.modifierGroups.length !== 0) {
            for (let j = 0; j < selectedItem.modifierGroups.length; j += 1) {
                const tempVal = selectedItem.modifierGroups[j].modifiers.reduce((n, { quantity }) => n + quantity, 0);
               if (selectedItem.modifierGroups[j].maxAllowed && selectedItem.modifierGroups[j].maxAllowed < tempVal) {
                setValidation(true);
                return false;
               } else if (selectedItem.modifierGroups[j].minRequired && selectedItem.modifierGroups[j].minRequired > tempVal) {
                setValidation(true);
                return false;
               }
            }
        }
        const lineItemModifiers = [];
        let tempObject = {};
        for (let i = 0; i < lineItems.length; i += 1) {
            tempObject.quantity = lineItems[i].quantity;
            tempObject.modifierGid = lineItems[i].gid;
            tempObject.price = lineItems[i].price;
            tempObject.name = lineItems[i].name;
            tempObject.onlineName = lineItems[i].onlineName;

            if (lineItems[i].maxStackableQuantity === 1) {
                tempObject.isStackable = true;
            } else {
                tempObject.isStackable = false;
            }
            lineItemModifiers.push(tempObject);
            tempObject = {};
          } 
        const item = {
            quantity,
            itemGid: selectedItem.gid,
            price: selectedItem.price,
            name: selectedItem.name,
            onlineName: selectedItem.onlineName,
            isExst: false,
            lineItemModifiers,
            note: notes,
            discountedPrice: selectedItem.discountedPrice,
            coupon: selectedItem.coupon ? selectedItem.coupon : null,
        };
        submit(item);
        for (let k = 0; k < selectedItem.modifierGroups.length; k += 1) {
            if (selectedItem.modifierGroups[k].quantity && selectedItem.modifierGroups[k].quantity > 0) {
                selectedItem.modifierGroups[k].quantity = 0;
            }
        }
    }

    const closeModal = () => {
        close();
        for (let k = 0; k < selectedItem.modifierGroups.length; k += 1) {
            if (selectedItem.modifierGroups[k].quantity && selectedItem.modifierGroups[k].quantity > 0) {
                selectedItem.modifierGroups[k].quantity = 0;
            }
        }
    }

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            footer={null}
            onCancel={() =>closeModal()}
        >
            <div
                style={{
                    backgroundImage: `url(${selectedItem.imageUrl ? selectedItem.imageUrl : '' })`,
                    backgroundSize: 'contain',
                    backgroundColor: '#DADADA',
                    minHeight: '200px',
                    borderRadius: '10px 10px 0 0',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                <div class="d-flex justify-content-end align-items-center px-2 py-2 pb-3">
                    <img src={CloseIcon} height='30' width='30' onClick={() => closeModal()} style={{ cursor: 'pointer', backgroundColor: '#fff', borderRadius: '15px' }} />
                </div>
            </div>

            <div className="px-4">
                <div className="mb-3 mt-2">
                    <SpText fontSize="18px" color='#000000' fontWeight='700'>{selectedItem.onlineName && selectedItem.onlineName.length !==0 ? selectedItem.onlineName : selectedItem.name}&nbsp;</SpText>
                    <SpText fontSize="14px" color='#5C5C5C' fontWeight='500'>$ {(selectedItem.price / 100).toFixed(2)}</SpText>
                </div>
                {/* <div className="mb-3">
                    
                </div> */}
                <div className="mb-3">
                    <SpText fontSize="14px" color='#000000' fontWeight='400'>{selectedItem.description}</SpText>
                </div>
                {
                shopGid != 'shop-9c0041850614477084eef5309e85cdf0' ?
                    <div className="mb-3" style={{ border: '1px solid #D9D9D9', borderRadius: '10px', padding: '10px' }}>
                        <Row type="flex" justify="space-between" align="middle">
                            <Col span={12} className='justify-content-start align-items-center'>
                                <SpText fontSize="16px" color='#000000' fontWeight='600'>Quantity</SpText>
                            </Col>
                            <Col span={12} className='justify-content-end align-items-center' style={{textAlign:"end"}}>
                                <SpText fontSize="22px" color='#000000' fontWeight='600' onClick={() => reduceQuant()} style={{ cursor: 'pointer', padding: '0px 10px' }}>&ndash;</SpText>
                                <SpText fontSize="18px" color='#000000' fontWeight='600' style={{ padding: '0px 10px' }}>{quantity}</SpText>
                                <SpText fontSize="22px" color='#000000' fontWeight='600' onClick={() => incrseQuant()} style={{ cursor: 'pointer', padding: '0px 10px' }}>+</SpText>
                            </Col>
                        </Row>
                    </div> :
                    <></>
                }
                {
                    shopGid != 'shop-9c0041850614477084eef5309e85cdf0' && selectedItem.modifierGroups && selectedItem.modifierGroups.length !== 0 && (
                        <div className="mb-3" style={{ border: '1px solid #D9D9D9', borderRadius: '10px', padding: '10px' }}>
                            {
                                selectedItem.modifierGroups.map((items, index) => (
                                    <Row type="flex" justify="space-between" align='middle'>
                                        <Col span={24} className='justify-content-start align-items-center mb-2 mt-2'>
                                            <SpText fontSize="16px" color='#000000' fontWeight='600'>{items.onlineName && items.onlineName.length !==0 ? items.onlineName  : items.name}   </SpText>
                                            {
                                                items.minRequired && items.maxAllowed ? (
                                                    <SpText fontSize="12px" color='#000000' fontWeight='400' style={{fontStyle:'italic'}}>&nbsp; (choose {items.minRequired} max {items.maxAllowed})</SpText>
                                                ) : (
                                                    <>&nbsp;</>
                                                )
                                            }
                                            {
                                                !items.minRequired && items.maxAllowed ? (
                                                    <SpText fontSize="12px" color='#000000' fontWeight='400' style={{fontStyle:'italic'}}>&nbsp; (max {items.maxAllowed})</SpText>
                                                ) : (
                                                    <>&nbsp;</>
                                                )
                                            }
                                            {
                                                items.minRequired && !items.maxAllowed ? (
                                                    <SpText fontSize="12px" color='#000000' fontWeight='400' style={{fontStyle:'italic'}}>&nbsp; (choose {items.minRequired})</SpText>
                                                ) : (
                                                    <>&nbsp;</>
                                                )
                                            }
                                        </Col>
                                        <Col span={24} className='justify-content-start align-items-center'>
                                            {
                                                items.modifiers.map((item, i) => (
                                                    <Row type="flex" justify="space-between" align='middle'>
                                                        <Col span={8} className='justify-content-start align-items-center'>
                                                            <SpText fontSize="14px" color='#000000' fontWeight='500' style={{ textDecoration: item.available ? '' : 'line-through' }}>{item.name}</SpText>
                                                        </Col>
                                                        {
                                                            items.maxAllowed === 1 ? (
                                                                <Col span={10} className='justify-content-start align-items-center' style={{ textAlign: "center" }}>
                                                                    <Checkbox
                                                                        checked={item.quantity === 1}
                                                                        onChange={({ target: { checked } }) => {
                                                                            stackModifier(checked, item, items, index, i);
                                                                        }}
                                                                    />
                                                                </Col>
                                                            ) : (
                                                                <Col span={10} className='justify-content-start align-items-center' style={{ textAlign: "center" }} >
                                                                    <SpText fontSize="22px" color='#000000' fontWeight='600' onClick={() => reduceModifierQuant(item, index, i)} style={{ cursor: 'pointer', padding: '0px 10px' }}>&ndash;</SpText>
                                                                    <SpText fontSize="18px" color='#000000' fontWeight='600' style={{ padding: '0px 10px' }}>{item.quantity ? item.quantity : 0}</SpText>
                                                                    {
                                                                        item.maxStackableQuantity !== item.quantity && selectedItem.modifierGroups[index].maxAllowed !== selectedItem.modifierGroups[index].quantity ? (
                                                                            <>
                                                                            {
                                                                                item.available ? (
                                                                                    <SpText fontSize="22px" color='#000000' fontWeight='600' onClick={() => incrseModifierQuant(item, index, i)} style={{ cursor: 'pointer', padding: '0px 10px' }}>+</SpText>
                                                                                ) : (
                                                                                    <SpText fontSize="22px" color='#000000' fontWeight='600' style={{ padding: '0px 10px' }}>+</SpText>
                                                                                )
                                                                            }
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }

                                                                </Col>
                                                            )
                                                        }
                                                        {/* <Col span={10} className='justify-content-start align-items-center' style={{textAlign:"center"}}>
                                                            <SpText fontSize="14px" color='#000000' fontWeight='500'>{item.name}</SpText>
                                                        </Col> */}
                                                        {
                                                            item.discountedPrice ? (
                                                                <Col span={6} className='justify-content-start align-items-center' style={{ textAlign: "center" }}>
                                                                    {
                                                                        item.discountedPrice > 0 ? (
                                                                            <SpText fontSize="14px" color='#000000' fontWeight='500'>$ {(((item.discountedPrice)) / 100).toFixed(2)}</SpText>
                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }
                                                                </Col>
                                                            ) : (
                                                                <Col span={6} className='justify-content-start align-items-center' style={{ textAlign: "center" }}>
                                                                    {
                                                                        item.price > 0 ? (
                                                                            <SpText fontSize="14px" color='#000000' fontWeight='500'>$ {(((item.price)) / 100).toFixed(2)}</SpText>
                                                                        ) : (
                                                                            <></>
                                                                        )

                                                                    }
                                                                    
                                                                </Col>
                                                            )
                                                        }
                                                    </Row>
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    )
                }
                {/* <div className="mb-3" style={{ border: '1px solid #D9D9D9', borderRadius: '10px', padding: '10px' }}>
                    <Row type="flex" justify="space-between">
                        <Col span={24} className='justify-content-start align-items-center'>
                            <label>
                                <SpText fontSize="16px" color='#000000' fontWeight='700'>Special instructions</SpText>
                                <SpText fontSize="12px" color='#000000' fontWeight='400' style={{fontStyle:'italic'}}>&nbsp; (Optional)</SpText>
                            </label>
                            <TextArea className='mt-2'
                                rows={2}
                                maxlength="300"
                                value={notes}
                                onChange={(e) => setNotes(e.currentTarget.value)}
                            />
                        </Col>
                    </Row>
                </div> */}
                <Row className='mt-5 mb-2'>
                    {
                        validation ? (
                            <Col span={24}>
                                <SpText fontSize="16px" color='#FF0000' fontWeight='600'>Please add required modifiers</SpText>
                            </Col>
                        ) : (
                            <>&nbsp;</>
                        )
                    }
                </Row>
                {
                shopGid != 'shop-9c0041850614477084eef5309e85cdf0' ?
                <Row>
                    <Col span={24}>
                        <Button className='searchDateGradientBtn w-100 ' style={{ height: '45px', fontSize: '18px', fontWeight: '500' }} onClick={() => validate()}>
                            <Row type="flex" justify="space-between">
                                <Col span={12} className='justify-content-start align-items-center'>
                                    <span style={{ fontSize: '18px', fontWeight: '600' }}>Add to Cart</span>
                                </Col>
                                <Col span={12} className='justify-content-end align-items-center'>
                                    <span style={{ fontSize: '18px', fontWeight: '600' }}>$ {((splitUpInfo ? splitUpInfo.subTotal : selectedItem.price * quantity) / 100).toFixed(2)}</span>
                                </Col>
                            </Row>
                        </Button>

                    </Col>
                </Row> : <></>
            }
            </div>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddItems);
