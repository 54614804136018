// @flow
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';

import type { ContextRouter } from 'react-router-dom';

import { notificationActions } from '../../store/actions';
import { NOTIFICATION } from '../../store/actionTypes';

type Props = ContextRouter & {
  success: boolean,
  error: boolean,
  thankyou: boolean,
  resetNotification: Function,
};

const KEY = 'updatable';

const openNotification = ({ success, error, thankyou, onClose }) => {
  notification.open({
    KEY,
    message: success ? 'Success' : (thankyou ? 'Thank You': 'Error'),
    description: success || error || thankyou,
    duration: success ? 5 : 5,
    className: `${success ? 'bg-success' : 'bg-danger'} text-white`,
    // style:{color:'#FFFFFF',background: '#111110',boxShadow:'0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2)',borderRadius:'8px'},
    onClose: () => onClose(),
  });
};

const Notification = (props: Props) => {
  const { success, error, resetNotification, thankyou } = props;

  useEffect(() => {
    openNotification({
      success,
      error,
      thankyou,
      onClose: resetNotification,
    });
  }, [success, error, thankyou]);

  return <div />;
};

const mapStateToProps = (state) => ({
  success: state.notification.success,
  error: state.notification.error,
  thankyou: state.notification.thankyou,
});

const mapDispatchToProps = (dispatch) => ({
  resetNotification: () => dispatch(notificationActions.resetNotification({
    type: NOTIFICATION.RESET,
  })),
});

export default connect<*, *, *, *, *, *>(
  mapStateToProps,
  mapDispatchToProps,
)(Notification);
