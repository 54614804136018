import { CART } from '../actionTypes';

export const getCartVisible = (action) => ({
    type: CART.SET_VISIBLE,
    payload: action.payload,
  });

  export const getShopInfo = (action) => ({
    type: CART.SHOP_INFO,
    payload: action.payload,
  });

  export const getCustomerInfo = (action) => ({
    type: CART.CUSTOMER_INFO,
    payload: action.payload,
  });

  export const getItemsInfo = (action) => ({
    type: CART.ITEMS_INFO,
    payload: action.payload,
  });

  export const getMobileView = (action) => ({
    type: CART.GET_MOBILE_VIEW,
    payload: action.payload,
  });

  export const getInventData = (action) => ({
    type: CART.GEI_INVENTORY_ORDER,
    payload: action.payload,
  });

  export const getUserInfo = (action) => ({
    type: CART.USER_PROFILE,
    payload: action.payload,
  });

  export const requestQuickSupports = (action) => ({
    type: CART.QUICK_SUPPORT,
    payload: action.payload,
  });

  export const getSearchItems = (action) => ({
    type: CART.SEARCH_ITEMS,
    payload: action.payload,
  });

  export const setSearchItems = (action) => ({
    type: CART.SEARCH_SET,
    payload: action.payload,
  });

  export const setSelectedShopInfo = (action) => ({
    type: CART.SELECTED_SHOP_INFO,
    payload: action.payload,
  });

  export const setSelectedShopInfoTemp = (action) => ({
    type: CART.SELECTED_SHOP_INFO_TEMP,
    payload: action.payload,
  });

  export const setShopOpen = (action) => ({
    type: CART.SHOP_OPEN,
    payload: action.payload,
  });
