// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Row,
    Col,
    Button,
} from 'antd';
import {
    cartActions,
    notificationActions,
} from '../../../store/actions';
import { SpText } from '../../../components/DesignKit';
import CloseIcon from "../../../assets/imgs/close.svg";

type Props = {
    close: Function,
    submit: Function,
    visible: Boolean,
    payWithSwirepay: Function,
};

const PlaceOrder = (props: Props) => {
    const {
        visible,
        close,
        payWithSwirepay,
    } = props;

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            footer={null}
        >
            <div class="d-flex justify-content-end align-items-center">
                <img src={CloseIcon} height='20' width='20' onClick={() => close()} style={{ cursor: 'pointer' }} />
            </div>
            <Row type="flex" justify="space-between">
              <Col span={24} className="d-flex justify-content-center align-items-center">
                <SpText fontSize="16px" color="#00000" fontWeight="500">Would you like to</SpText>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={24}>
                <Button className='searchDateGradientBtn w-100' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => payWithSwirepay()}>
                    Pay for your order now?
                </Button>
              </Col>
            </Row>
            <Row className='mt-3'>
            <Col span={24} className="d-flex justify-content-center align-items-center">
                <SpText fontSize="16px" color="#00000" fontWeight="500">OR</SpText>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col span={24}>
                <Button className='searchDateGradientBtn1 w-100' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => close()}>
                Order more item and pay later?
                </Button>
              </Col>
            </Row>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.cartInfo.isMobileView,
    inVentData: state.cartInfo.inVentData,
    userInfo: state.cartInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
    getItemsInfo: param => dispatch(cartActions.getItemsInfo({
        payload: param,
    })),
    getUserInfo: param => dispatch(cartActions.getUserInfo({
        payload: param,
    })),
    setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
        type,
        payload,
    })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrder);
