// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Menu,
  Icon,
  Divider,
  Row,
  Col,
} from 'antd';
import { slide as Menus } from 'react-burger-menu';
import { withRouter } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import styled from 'styled-components';

import getSideNavOptions from '../../config/sidenav';
import { API_END_POINTS, axiosAuthInstance } from '../../api';
import {
  PAGES,
  TOKENS,
  ROLES,
} from '../../appconstants';
import {
  accountActions,
  notificationActions,
  terminalActions,
  cartActions,
} from '../../store/actions';
import {
  ACCOUNT,
  NOTIFICATION,
  TERMINAL,
} from '../../store/actionTypes';

import mbLogo from '../../assets/imgs/roundedLogo.svg';
import water from '../../assets/water.svg';
import cultry from '../../assets/cultry.svg';
import ToGo from '../../assets/checkCircle.svg';
import Help from '../../assets/help.svg';
import menuIcon from '../../assets/navigate.svg';
import mblMenu from '../../assets/storePick.svg';

import {
  SpText,
  SpStyledMenu,
} from '../DesignKit';

const StyledMenu = styled(Menu)`
  background: #12284A;
  border: none;
`;

const StyledMenuItem = styled(Menu.Item)`
  height: 40px !important;
  padding: 6px 13px 6px 22px !important;
`;

const { Sider } = Layout;

const COLLAPSIBLE = false;

type Props = ContextRouter & {
  history: {
    push: Function,
  },
  location: {
    pathname: string,
  },
  setNotification: Function,
  isMobileView: Boolean,
  shopInfo: Array<Object>,
  setNotification: Function,
  requestQuickSupports: Function,
  browseMode: Boolean,
  userInfo: Object,
  setSelectedShopInfo: Function,
  selectedShopInfo: Object,
  itemsInfos: Array<Object>,
  setSelectedShopInfoTemp: Function,
};

const SideNav = (props: Props) => {
  const {
    history,
    location,
    isMobileView,
    shopInfo,
    setNotification,
    requestQuickSupports,
    browseMode,
    userInfo,
    setSelectedShopInfo,
    selectedShopInfo,
    itemsInfos,
    setSelectedShopInfoTemp,
  } = props;
  const [current, setCurrent] = useState('dashboard');
  const [collapsed, setCollapsed] = useState(false);
  const [sideNavOptions, setSideNavOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const [quickSupportInfo, setQuickSupportInfo] = useState([]);
  const [selectedShopName, setSelectedShopName] = useState('');
  const [selectedShopId, setSelectedShopId] = useState('');

  const requestQuickSupport = (item) => {
    if (itemsInfos && itemsInfos.length !== 0) {
      setSelectedShopInfoTemp(item);
      setOpen(!isOpen);
      return false;
    }
    setSelectedShopName(item.name);
    localStorage.setItem('selectedShop', item.gid);
    setSelectedShopInfo(item);
    setOpen(!isOpen);
  };

  useEffect(() => {
    if (shopInfo && shopInfo.length !== 0 && selectedShopInfo && selectedShopInfo.gid) {
      setSelectedShopId(selectedShopInfo.gid);
      setSelectedShopName(selectedShopInfo.name);
    }
  }, [selectedShopInfo, shopInfo]);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  }

  return (
    <>
      {
        !browseMode && (
          <Menus isOpen={isOpen} onOpen={handleIsOpen} onClose={handleIsOpen} customBurgerIcon={<span><img src={mblMenu} right />&nbsp;&nbsp;{selectedShopName}&nbsp;&nbsp;&nbsp;&nbsp;<img src={menuIcon} right /></span>}>
            <div className='mb-navs' style={{ fontSize: '18px', color: '#000', fontWeight: '600', padding: isMobileView ? '0px 10px' : '0px' }}>&nbsp;Select store</div>
            {
              shopInfo && shopInfo.length !== 0 && (
                <>
                  {
                    shopInfo.map((item) => (
                      <Row type="flex" justify="space-between" align="middle"
                        style={{ borderBottom: item.gid === selectedShopId ? '1px solid #2B5EAD' : '1px solid #999999', cursor: 'pointer', padding: isMobileView ? '0px 10px' : '0px' }}
                        onClick={() => requestQuickSupport(item)}
                      >
                        <Col span={item.gid === selectedShopId ? 20 : 24} className="d-flex align-items-center mt-3">
                          <a id={item.gid} className="menu-item">
                            <div>
                              <SpText
                                fontSize="16px"
                                fontWeight="600"
                                color={item.gid === selectedShopId ? '#2B5EAD' : '#292929'}
                                style={{ cursor: 'pointer' }}
                              >
                                {item.name}
                              </SpText>
                            </div>
                          </a>
                        </Col>
                        {
                          item.gid === selectedShopId ? (
                            <Col span={4} className="d-flex justify-content-end align-items-center mt-3">
                              <img src={ToGo} />
                            </Col>
                          ) : (
                            <></>
                          )
                        }
                      </Row>
                    ))
                  }
                </>
              )
            }
          </Menus>
        )
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  isMobileView: state.cartInfo.isMobileView,
  shopInfo: state.cartInfo.shopInfo,
  browseMode: state.cartInfo.browseMode,
  userInfo: state.cartInfo.userInfo,
  selectedShopInfo: state.cartInfo.selectedShopInfo,
  itemsInfos: state.cartInfo.itemsInfos,
});

const mapDispatchToProps = (dispatch) => ({
  requestQuickSupports: param => dispatch(cartActions.requestQuickSupports({
    payload: param,
  })),
  setSelectedShopInfoTemp: param => dispatch(cartActions.setSelectedShopInfoTemp({
    payload: param,
  })),
  setSelectedShopInfo: param => dispatch(cartActions.setSelectedShopInfo({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SideNav));
