import React from "react";
import { Row, Col } from "antd";
import MainHeader from "../PageOptions";

export default function index() {
  return (
    <div>
      <MainHeader />
      <div className="onlineOrdersBody">
        <Row justify="center">
          <Col span={24} className="mainImg">
            <div className="MainImg-text">
              <h1 style={{ color: "white", fontSize: "56px" }}>Checkout</h1>
            </div>
          </Col>
          
        </Row>
      </div>
    </div>
  );
}
