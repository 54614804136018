// Reference: https://tylermcginnis.com/react-router-protected-routes-authentication/
import React from "react";
// import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import type { ComponentType } from "react";

import routes from "../../config/routes";

import { PAGES, TOKENS } from "../../appconstants";

const ProtectedRoute = ({
  component: Component,
  ...rest
}: {
  component: ComponentType,
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (localStorage.getItem(TOKENS.ACCESS_TOKEN)) {
        // find current route
        const activeRoute = routes.find((route) => route.path === rest.path);

        // check if role is allowed to see this page
        // const roleAllowed = activeRoute.allowedRoles.find(allowedRole => (rest.role && allowedRole === rest.role.name));
        // check if role is allowed to see this page
        // const internalRoleAllowed = activeRoute.allowedRoles.find(allowedRole => (rest.internalRole && allowedRole === rest.internalRole.name));
        if (activeRoute) {
          return <Component {...props} />;
        }
        return <Redirect to={PAGES.NO_MATCH} />;
      }
      // eslint-disable-next-line
      if (
        !localStorage.getItem(TOKENS.ACCESS_TOKEN) &&
        props.location &&
        props.location.pathname
      ) {
        return (
          <Redirect
            to={{
              pathname: PAGES.ONLINEORDER,
              search: "",
              state: { referrer: props.location }, // eslint-disable-line react/prop-types
            }}
          />
        );
      }
      return <Redirect to={PAGES.ONLINEORDER} />;
    }}
  />
);

// const mapStateToProps = (state) => ({
//   role: state.account.accounts.role,
// });

export default ProtectedRoute;
