import Membership from "../routes/orders";
import NoMatch from "../routes/NoMatch";
import OnlineOrders from "../routes/OnlineOrders";
import Checkout from "../routes/Checkout";
import Summary from "../routes/Summary";
import InvalidSlug from "../routes/InvalidSlug";

import { ROLES } from "../appconstants";

const routes = [
  {
    key: "orders",
    path: "/:slugname",
    protected: false,
    exact: true,
    component: OnlineOrders,
  },

  {
    key: "checkout",
    path: "/store/checkout",
    protected: false,
    exact: true,
    component: Checkout,
  },
  {
    key: "summary",
    path: "/receipt/:slugname",
    protected: false,
    exact: true,
    component: Summary,
  },
  {
    key: "InvalidSlug",
    path: "/account/invalidSlug",
    protected: false,
    exact: true,
    component: InvalidSlug,
  },
  {
    key: "404",
    path: "",
    protected: true,
    exact: false,
    component: NoMatch,
    allowedRoles: [],
  },
];

export default routes;
