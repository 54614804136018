import axios from 'axios';
import {
  TOKENS,
  PAGES,
} from '../appconstants';

export const ROOT_URL = process.env.REACT_APP_API_ENDPOINT;
export const ROOT_LOGIN_URL = process.env.REACT_APP_LOGIN_API_ENDPOINT;
export const ROOT_URL2 = process.env.REACT_APP_API2_ENDPOINT;
export const ROOT_QUICKBOOKS_URL = process.env.REACT_APP_QUICKBOOKS_API_ENDPOINT;
export const ROOT_QUICKBOOKS_BASE_URL = process.env.REACT_APP_QUICKBOOKS_BASE_ENDPOINT;
export const ROOT_SALESFORCE_URL = process.env.REACT_APP_SALESFORCE_API_ENDPOINT;
export const ROOT_SALESFORCE_BASE_URL = process.env.REACT_APP_SALESFORCE_BASE_ENDPOINT;
export const ROOT_SUPEREST_URL = process.env.REACT_APP_SUPEREST_ENDPOINT;
export const ROOT_SUPEREST_DOMAIN = process.env.REACT_APP_SUPEREST_DOMAIN;

export const axiosSuperestInstance = axios.create({
  baseURL: ROOT_SUPEREST_URL,
});

export const axiosAuthInstance = axios.create({
  baseURL: ROOT_URL,
});

export const axiosAuthInstance2 = axios.create({
  baseURL: ROOT_URL2,
});

export const axiosLoginInstance = axios.create({
  baseURL: ROOT_LOGIN_URL,
});

export const axiosLoginAuthInstance = axios.create({
  baseURL: ROOT_LOGIN_URL,
});

export const axiosNoAuthInstance = axios.create({
  baseURL: ROOT_URL,
});

export const axiosEmptyInstance = axios.create({
  baseURL: '',
});

export const axiosQuickBooksAuthInstance = axios.create({
  baseURL: ROOT_QUICKBOOKS_URL,
});

export const axiosSalesForceAuthInstance = axios.create({
  baseURL: ROOT_SALESFORCE_URL,
});

export const API_END_POINTS = {
  GET_PUBLICK_KEY: '/shop/slug',
  GET_SHOP_INFO: '/shop-table',
  GET_INVENTORY_INFO: '/inventory/category',
  GET_ITEMS: '/inventory/item/category',
  GET_SPLIT_UP: '/inventory/order/inventory-order-split-up',
  QR_ORDER: '/inventory/order/qr-order',
  ORDER_TYPE: '/inventory/order-type',
  TABLE_ORDER: '/table-order',
  GET_INVENTORY_ORDER: '/inventory/order',
  SUPERSET_DASHBOARD: '/superset-dashboard',
  TABLE_RESERVATION: '/shop-table-reservation',
  SHOP_TABLE: '/quick-support',
  SHOP_REQUEST: '/shop/quick-support',
  UPDATE_CUSTOMERS: '/customer',
  FCM_DETAILS: '/fcmdetails',
  GET_ITEMS_DETAILS: '/inventory/item/shop',
  GET_ALL_ITEMS: 'inventory/category/items/shop',
  GET_ACCOUNT: '/account',
  GET_ALL_MENU_ITEMS: 'inventory/category',
  GET_ALL_SHOPS: '/shop',
  GET_ALL_SHOPS_SLUGS: '/account-slug/shop-page/',
  GET_MENU_CATEGEORY: '/inventory/menu/shop',
};

axiosAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': `${localStorage.getItem('publicKey')}`,
  };
  config.headers = headers;
  return config;
}, (err) => {
  Promise.reject(err);
});

axiosAuthInstance2.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

axiosQuickBooksAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

axiosSalesForceAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

// Add a response interceptor
axiosAuthInstance.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});

axiosAuthInstance2.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});

axiosLoginAuthInstance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = `Bearer ${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`;
  return config;
}, (err) => {
  Promise.reject(err);
});

// Add a response interceptor
axiosQuickBooksAuthInstance.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});

// Add a response interceptor
axiosSalesForceAuthInstance.interceptors.response.use(response => response, err => {
  const originalReq = err.config;
  // eslint-disable-next-line
  if (err.response.status === 401 && !err.config.__retry) {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      originalReq.__retry = true;
      const headers = {
        'Content-Type': 'application/json',
        'Refresh-Token': `${localStorage.getItem(TOKENS.REFRESH_TOKEN)}`,
      };
      const params = {
        accessToken: `${localStorage.getItem(TOKENS.ACCESS_TOKEN)}`,
        email: `${localStorage.getItem('email')}`,
      };
      axiosLoginInstance.post(`${API_END_POINTS.TOKEN_REFRESH}`, params, {
        headers,
      })
        .then(res => {
          localStorage.setItem(TOKENS.ACCESS_TOKEN, res.data.entity.token.accessToken);
          localStorage.setItem(TOKENS.REFRESH_TOKEN, res.data.entity.token.refreshToken);
          resolve(axiosAuthInstance(originalReq));
        })
        .catch((e) => {
          console.log(e);
          localStorage.removeItem(TOKENS.ACCESS_TOKEN);
          localStorage.removeItem(TOKENS.REFRESH_TOKEN);
          localStorage.removeItem('email');
          window.location.assign(PAGES.LOGIN);
        });
    });
  }
  return Promise.reject(err);
});
