import { React, useContext, useEffect, useState, useRef, createRef, useMemo } from "react";
import { Row, Col, Modal, Card, Input, Tabs, Button, Radio, Select, Collapse } from "antd";
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment-timezone';

import AddItems from './Components/AddItems';
import EditItem from "./Components/EditItem";
import NavBar from "./Components/NavBar";
import NavBarMenu from "./Components/NavBarMenu";
import Profile from "./Components/Profile";

import {
  axiosAuthInstance,
  axiosNoAuthInstance,
  API_END_POINTS,
  ROOT_URL,
} from '../../api';

import {
  notificationActions,
  cartActions,
} from '../../store/actions';

import {
  SpText,
  SpStyledMenu,
  SpError,
} from '../../components/DesignKit';
import formatAmount from '../../utils/formatAmount';
import Loading from '../../components/Loading';
import CloseIcon from "../../assets/imgs/close.svg";
import CartLogo from "../../assets/imgs/cartLogo.svg";
import Back from '../../assets/imgs/back.svg';
import Chart from '../../assets/imgs/chart.svg';
import checkoutCart from '../../assets/checkoutCart.svg';
import ArrowCart from '../../assets/arrowCart.svg';
import PlaceOrder from "./Components/PlaceOrder";
import ToastMessages from "./Components/ToastMessages";
import ShopSelection from "./Components/ShopSelection";
import Unavailable from "./Components/Unavailable";
import AddOn from '../../assets/imgs/addOn.svg';
import ArrImg from '../../assets/arr.svg';
import { PAGES } from "../../appconstants";

const { Search } = Input;

const { TabPane } = Tabs;

const { Panel } = Collapse;

type Props = {
  history: {
    push: Function,
  },
  isCartVisible: Boolean,
  getCartVisible: Function,
  getShopInfo: Function,
  shopInfo: Object,
  getCustomerInfo: Function,
  itemsInfos: Array<object>,
  getItemsInfo: Function,
  isMobileView: Boolean,
  getInventData: Function,
  inVentData: Array<object>,
  userInfo: Object,
  quickSupport: String,
  requestQuickSupports: Function,
  getUserInfo: Function,
  getSearchItems: Function,
  searchItems: Array<Object>,
  setSearchItems: Function,
  searchActive: Boolean,
  setSelectedShopInfo: Function,
  selectedShopInfo: Object,
  setSelectedShopInfoTemp: Function,
  selectedShopInfoTemp: Object,
  isShopOpen: Boolean,
};


const OnlineOrders = (props: Props) => {
  const {
    history,
    isCartVisible,
    getCartVisible,
    getShopInfo,
    shopInfo,
    getCustomerInfo,
    itemsInfos,
    getItemsInfo,
    isMobileView,
    getInventData,
    inVentData,
    userInfo,
    quickSupport,
    requestQuickSupports,
    getUserInfo,
    getSearchItems,
    searchItems,
    setSearchItems,
    searchActive,
    setSelectedShopInfo,
    selectedShopInfo,
    setSelectedShopInfoTemp,
    selectedShopInfoTemp,
    isShopOpen,
  } = props;
  const [isShopSelection, setIsShopSelection] = useState(false);
  const [isAddItemVisible, setIsItemVisible] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const slugName = pathname.replace("/", "");
  const publickKey = localStorage.getItem('publicKey');
  const shopGid = localStorage.getItem('shopGid');
  const [inventoryInfo, setInventoryInfo] = useState([]);
  const [itemsInfo, setItemsInfo] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [splitUpInfo, setSplitUpInfo] = useState();
  const [orderType, setOrderType] = useState();
  const [disabled, setDisabled] = useState(false);
  const [isOrderPlaced, setIsOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isTableId, setIsTableId] = useState(false);
  const [status, setStatus] = useState('');
  const [itemKey, setItemKey] = useState('');
  const [mblCat, setMblCat] = useState(false);
  const [defaultTab, setDefaultTab] = useState('');
  const [customTip, setCustomTip] = useState(false);
  const [amount, setAmount] = useState();
  const [tipPercentage, setTipPercentage] = useState();
  const [tipType, setTipType] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [promoMessage, setPromoMessage] = useState('');
  const [isPlacedOrder, setIsPlacedOrder] = useState(false);
  const [isModifierVisible, setIsModifierVisible] = useState(false);
  const [selectedInventoryOrder, setSelectedInventoryOrder] = useState({});
  const [scrollPosition, setScrollPosition] = useState(0);
  const [toastMessageModal, setToastMessageModal] = useState(false);
  const [message, setMessage] = useState('');
  const [typeOfMessage, setTypeOfMessage] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [allCatItems, setAllCatItems] = useState([]);
  const [catList, setCatList] = useState([]);
  const [isEditItemModal, setIsEditItemModal] = useState(false);
  const [editItem, setEditItem] = useState();
  const [selectedSequence, setSelectedSequence] = useState();
  const [tips , setTips] = useState([])
  const [isFloating, setIsFloating] = useState(false);
  const [shopList, setShopList] = useState([]);
  const [selectedShop, setSlectedShop] = useState('');
  const [orderMode, setOrderMode] = useState();
  const [payAtStore, setPayAtStore] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [menusList, setMenusList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState();
  const [unavailableModal, setUnavailableModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [openPanels, setOpenPanels] = useState([]);
  const [displayMessage, setDisplayMessage] = useState('');

  const redirectUrl = (ROOT_URL === 'https://staging-backend.swirepay.com/v1') ? 'https://staging-shop.swirepay.com' : 'https://shop.swirepay.com';

  const getPublicKey = async (slugName) => {
    try {
      const { data: { entity } } = await axiosNoAuthInstance.get(`${API_END_POINTS.GET_PUBLICK_KEY}/${slugName}`);
      localStorage.setItem('publicKey', entity.publicKey);
      localStorage.setItem('shopGid', entity.gid);
      localStorage.setItem('banner', entity.banner);
      localStorage.setItem('accountName', entity.name);
      localStorage.setItem('timeZone', entity && entity.account && entity.account.timezone);
      setSelectedAccount(entity.account);
      setOrderMode(entity && entity.orderMode);
      setPayAtStore(entity && entity.enablePayAtStore);
      setSlectedShop(entity.gid);
      getMenusInfo(entity.gid);
      setTips(entity.tips)

    } catch (e) {
      console.error(e);
      if (e.response.status === 412) {
        history.push("/account/invalidSlug");
      }
    }
  };

  useEffect(() => {
      getPublicKey(slugName);
  }, [slugName, location]);

  useEffect(() => {
    const tempDiv = document.getElementsByClassName('ant-layout');
    if (tempDiv && tempDiv[0] && isMobileView && isCartVisible) {
      tempDiv[0].style.position = 'fixed';
    } else {
      tempDiv[0].style.position = '';
      tempDiv[0].style.width = '100%';
    }
  }, [isMobileView, isCartVisible]);

  const [fix, setFix] = useState(false);

  function setFixed() {
    const headerHeight = document.getElementsByTagName('header')[0].offsetHeight;
    if (window.scrollY >= headerHeight) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [itemsInfo, toastMessageModal, isCartVisible]);

  const savedPromoCode = localStorage.getItem('promo');

  useEffect(() => {
    if (savedPromoCode && splitUpInfo && splitUpInfo.orderDiscount) {
      setPromoCode(savedPromoCode);
    }
  }, [savedPromoCode]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsFloating(scrollY > 100); // Change 100 to the scroll position where you want the menu to start floating
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const inventId = parsed['sp-inventory-order'];
    if (inventId) {
      history.push(`${PAGES.RECEIPT}/${slugName}?inventId=${inventId}`);
    }
  }, [location]);

  const getSortNonOperating = (data) => {
    const selectedTimeZone = (selectedAccount && selectedAccount.timezone) ? selectedAccount.timezone : localStorage.getItem('timeZone');
    const today = moment.utc(new Date()).tz(selectedTimeZone).format('MMM DD, YYYY, hh:mm a');
    const tempToday = moment.utc(new Date()).tz(selectedTimeZone).format('MMM DD, YYYY');
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    const currentDay = data && data.filter((weekDay) => weekDay.dayOfWeek === day);
    const tempArray = [];
    if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length > 1) {
      for (let i = 0; i < currentDay[0].operatingHours.length; i += 1) {
        if (currentDay[0].operatingHours[i].openingTime > minutes && currentDay[0].operatingHours[i].open) {
          tempArray.push(currentDay[0].operatingHours[i]);
        }
      } 
    } else if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length === 1) {
      if (currentDay[0].operatingHours[0].openingTime > minutes && currentDay[0].operatingHours[0].open) {
        tempArray.push(currentDay[0].operatingHours[0]);
      }
    }
    if (tempArray && tempArray.length !== 0) {
      return `${tempToday}, ${getOperatingTime(tempArray[0].openingTime)}`;
    } else if (tempArray && tempArray.length === 0) {
      const weekCount = 8;
      for (let i = 1; i < weekCount; i += 1) {
        const next = new Date();
        next.setDate(next.getDate() + i);
        const tempNextDay = moment.utc(next).tz(selectedTimeZone).format('MMM DD, YYYY');
        const nextDay = days[new Date(tempNextDay).getDay()];
        const nextDate = data && data.filter((weekDay) => weekDay.dayOfWeek === nextDay);
        if (nextDate[0] && nextDate[0].operatingHours && nextDate[0] && nextDate[0].operatingHours.length > 1) {
          for (let i = 0; i < nextDate[0].operatingHours.length; i += 1) {
            if (nextDate[0].operatingHours[i].open) {
              return `${tempNextDay}, ${getOperatingTime(nextDate[0].operatingHours[i].openingTime)}`;
            }
          }
        } else if (nextDate[0] && nextDate[0].operatingHours && nextDate[0] && nextDate[0].operatingHours.length === 1) {
          if (nextDate[0].operatingHours[0].open) {
            return `${tempNextDay}, ${getOperatingTime(nextDate[0].operatingHours[0].openingTime)}`;
          } 
        }
      }
    }
    return '';
  };

  const operatingMenuOpening = (timings) => {
    const selectedTimeZone = (selectedAccount && selectedAccount.timezone) ? selectedAccount.timezone : localStorage.getItem('timeZone');
    const today = moment.utc(new Date()).tz(selectedTimeZone).format('MMM DD, YYYY, hh:mm a');
    const tempToday = moment.utc(new Date()).tz(selectedTimeZone).format('MMM DD, YYYY');
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    const currentDay = timings && timings.filter((weekDay) => weekDay.dayOfWeek === day);
    if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length > 1) {
      const tempArray = [];
      for (let i = 0; i < currentDay[0].operatingHours.length; i += 1) {
        if (currentDay[0].operatingHours[i].openingTime < minutes && currentDay[0].operatingHours[i].closingTime >= minutes && currentDay[0].operatingHours[i].open) {
          tempArray.push(currentDay[0].operatingHours[i].openingTime);
        }
      }
      if (tempArray && tempArray.length !== 0) {
        return `${tempToday}, ${getOperatingTime(tempArray[0])}`;
      }
    } else if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length === 1) {
      if (currentDay[0].operatingHours[0].openingTime < minutes && currentDay[0].operatingHours[0].closingTime >= minutes && currentDay[0].operatingHours[0].open) {
        return `${tempToday}, ${getOperatingTime(currentDay[0].operatingHours[0].openingTime)}`;
      }
    } 
  }

  const getMenusInfo = async (shopGid) => {
    setLoading(true);
    try {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.GET_MENU_CATEGEORY}/${shopGid}`);
      const nonOperatings = [];
      const operatings = [];
      if (entity && entity.length !== 0) {
        for (let i = 0; i < entity.length; i += 1) {
          entity[i].inventoryMenu.storeOpen = entity[i].inventoryMenu.operatingHoursMaster ? getSortOperating(entity[i].inventoryMenu.operatingHoursMaster.hoursOfOperations) : false;
        }
        entity.sort(function(a,b){return b.inventoryMenu.storeOpen-a.inventoryMenu.storeOpen});
        for (let j = 0; j < entity.length; j += 1) {
          if (!entity[j].inventoryMenu.storeOpen && entity[j].inventoryMenu.operatingHoursMaster) {
            entity[j].inventoryMenu.NextAvailable = getSortNonOperating(entity[j].inventoryMenu.operatingHoursMaster.hoursOfOperations);
            nonOperatings.push(entity[j]);
          } else {
            operatings.push(entity[j]);
          }
        }
        nonOperatings.sort(function(a,b){return new Date(a.inventoryMenu.NextAvailable)-new Date(b.inventoryMenu.NextAvailable)});
        for (let i = 0; i < operatings.length; i += 1) {
          operatings[i].inventoryMenu.NextAvailable = operatingMenuOpening(operatings[i].inventoryMenu.operatingHoursMaster.hoursOfOperations);
        }
        operatings.sort(function(a,b){return new Date(a.inventoryMenu.NextAvailable)-new Date(b.inventoryMenu.NextAvailable)});
        Array.prototype.push.apply(operatings,nonOperatings);
        entity.sort(function(a,b){return new Date(a.inventoryMenu.NextAvailable)-new Date(b.inventoryMenu.NextAvailable)});
        setMenusList(operatings);
        // setAllListOfItems(entity);
        getItemsInfo([]);
        setSelectedMenu(operatings[0]);
        setAllCatItems(operatings[0].categoryItems);
        setCatList(operatings[0].categoryItems);
        setItemKey(operatings[0].categoryItems && operatings[0].categoryItems[0] && operatings[0].categoryItems[0].inventoryCategory.gid);
      } else if (entity && entity.length === 0) {
        getAllItemsInfo(shopGid);
      }
    } catch (e) {
      console.log(e);   
    } finally {
      setLoading(false);
      return false;
    }
  };

  const getAllItemsInfo = async (shopId) => {
    setLoading(true);
    try {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.GET_ALL_ITEMS}/${shopId}`);
      if (entity && entity.length !== 0) {
        setAllCatItems(entity);
        setCatList(entity);
        getItemsInfo([]);
        if (entity && entity.length !== 0) {
          const menuItems = [];
          for (let i = 0; i < entity.length; i += 1) {
            menuItems.push(entity[i].inventoryCategory);
          }
          setInventoryInfo(menuItems);
          setItemKey(menuItems[0].gid);
        }
      }  
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const addItemsToCart = (order) => {
    if (isShopOpen) {
      const tempItems = itemsInfos.filter((item) => item.itemGid === order.itemGid && item.note === order.note && item.isExst === false && item.lineItemModifiers.length === 0);
      const tempItem1 = itemsInfos.filter((item) => item.itemGid === order.itemGid && !item.note);
      const itemList = itemsInfos.filter((item) => item.itemGid !== order.itemGid);
      const index = itemsInfos.findIndex(p => p.itemGid === order.itemGid && p.note === order.note && p.isExst === false && p.lineItemModifiers.length === 0);
      const iteLst = itemsInfos.filter((item) => item.itemGid === order.itemGid && item.isExst === false && order.lineItemModifiers.length !== 0);
      if (iteLst && iteLst.length !== 0) {
        const list = [...itemsInfos];
        list.push(order);
        getItemsInfo(list);
        setIsItemVisible(false);
        return false;
      }
      if (tempItems && tempItems.length !== 0) {
        const list = [...itemsInfos];
        list[index].quantity = list[index].quantity + order.quantity;
        getItemsInfo(list);
        setIsItemVisible(false);
        return false;
      } else {
        const list = [...itemsInfos];
        list.push(order);
        getItemsInfo(list);
        setIsItemVisible(false);
        return false;
      }
      getSearchItems([]);
      setSearchValue('');
    } 
  };

  const editItemsToCart = (order, num) => {
    if (isShopOpen) {
      const list = [...itemsInfos];
      list[num] = order;
      getItemsInfo(list);
      setIsEditItemModal(false);
    }
    
  };

  const reduceQuant = (gid, num) => {
    const index = itemsInfos.findIndex(p => p.itemGid === gid && p.isExst === false);
    // const index = itemsInfos.map(i => i.itemGid).indexOf(gid);
    const listItems = [...itemsInfos];
    if (listItems[num].quantity > 1) {
      listItems[num].quantity = listItems[num].quantity - 1;
      getItemsInfo(listItems);
    } else if (listItems[num].quantity === 1) {
      listItems.splice(num, 1);
      getItemsInfo(listItems);
    }
  }

  const reduceQuantMod = (mGid, num) => {
    const listItems = [...itemsInfos];
    const index = listItems[num].lineItemModifiers.findIndex(p => p.modifierGid === mGid);
    if (listItems[num].lineItemModifiers[index].quantity === 1) {
      listItems[num].lineItemModifiers.splice(index, 1);
    } else if (listItems[num].lineItemModifiers[index].quantity > 1) {
      listItems[num].lineItemModifiers[index].quantity = listItems[num].lineItemModifiers[index].quantity - 1;
    }
    getItemsInfo(listItems);
  };

  const incrseQuantMod = (mGid, num) => {
    const listItems = [...itemsInfos];
    const temItem = itemsInfos[num];
    const index = listItems[num].lineItemModifiers.findIndex(p => p.modifierGid === mGid);
    listItems[num].lineItemModifiers[index].quantity = listItems[num].lineItemModifiers[index].quantity + 1;
    getItemsInfo(listItems);
  };

  const incrseQuant = (gid, num) => {
    const index = itemsInfos.findIndex(p => p.itemGid === gid && p.isExst === false);
    const listItems = [...itemsInfos];
    itemsInfos[num].quantity = itemsInfos[num].quantity + 1;
    getItemsInfo(listItems);
  }

  const editSelectedItem = (order, num) => {
    const validateArray = [];
    for (let i = 0; i < catList.length; i += 1) {
      validateArray.push(...catList[i].items);
    }
    const index = validateArray.findIndex(p => p.gid === order.itemGid);
    const listItems = [...validateArray];
    setSelectedSequence(num);
    setEditItem(order);
    setSelectedItem(listItems[index]);
    setIsEditItemModal(true);
    getCartVisible(false);
  }

  const incrseModifierQuant = (order) => {
    const index = itemsInfo.findIndex(p => p.gid === order.itemGid);
    const modfic = itemsInfo[index].modifierGroups;
    for (let i = 0; i < modfic.length; i += 1) {
      if (modfic && modfic[i].modifiers.length !== 0) {
        for (let j = 0; j < modfic[i].modifiers.length; j += 1) {
          modfic[i].modifiers[j].quantity = 0;
        };
      }
    };
    itemsInfo[index].modifierGroups.modifiers = modfic;
    getCartVisible(false);
    setIsItemVisible(true);
    setSelectedItem(itemsInfo[index]);
  }

  const callback = (key) => {
    setSelectedInventoryOrder(key);
    setItemKey(key.gid);
  };

  useEffect(() => {
    if (inventoryInfo && inventoryInfo.length !== 0) {
      setDefaultTab(inventoryInfo[0].name)
    }
  }, [inventoryInfo]);

  useEffect(() => {
    if (searchActive) {
      setItemsInfo(searchItems);
    }
  }, [searchItems, searchActive]);

  const updateItem = (item) => {
    if (!isTableId) {
      setIsItemVisible(true);
      const modfic = item.modifierGroups;
      for (let i = 0; i < modfic.length; i += 1) {
        if (modfic && modfic[i].modifiers.length !== 0) {
          for (let j = 0; j < modfic[i].modifiers.length; j += 1) {
            modfic[i].modifiers[j].quantity = 0;
          };
        }
      };
      item.modifierGroups.modifiers = modfic;
      setSelectedItem(item);
    }
  };

  const custInfo = localStorage.getItem('customer');

  useEffect(() => {
    if (custInfo && (JSON.parse(custInfo)).name) {
      getCustomerInfo(JSON.parse(custInfo));
    }
  }, [custInfo]);

  const getSplitWithPromo = async () => {
    let params;
    if (tipType && tipPercentage) {
      params = {
        shopGid: selectedShop,
        items: itemsInfos,
        tipType: tipType,
        tip: Math.round(tipPercentage * 100) ,
        promoCode: promoCode,
      }
    } else {
      params = {
        shopGid: selectedShop,
        items: itemsInfos,
        promoCode: promoCode,
      }
    }
    try {
      const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.GET_SPLIT_UP, params);
      setSplitUpInfo(entity);
      setPromoMessage(entity.message);
      localStorage.setItem('promo', promoCode);
    } catch (e) {
      console.error(e);
      localStorage.removeItem('promo');
    }
  };

  const getSplitUpInfo = async (type, amount) => {
    let params;
    if (type === 'PERCENTAGE') {
      setCustomTip(false);
    } else if (type === 'AMOUNT') {
      setCustomTip(false);
    } else {
      setCustomTip(false);
      setTipPercentage();
    }
    setTipType(type);
    setTipPercentage(amount);
    if (type && amount) {
      params = {
        shopGid: selectedShop,
        items: itemsInfos,
        tipType: type,
        tip:  Math.round(amount * 100),
      }
    }else {
      params = {
        shopGid: selectedShop,
        items: itemsInfos,
      }
    }
    if (promoCode) {
      params.promoCode = promoCode;
    }
    try {
      const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.GET_SPLIT_UP, params);
      setSplitUpInfo(entity);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (itemsInfos.length !== 0) {
      getSplitUpInfo();
    }
    const validationArray = itemsInfos.filter((item) => item.isExst === false);
    if (itemsInfos && itemsInfos.length === 0) {
      getCartVisible(false);
    }
  }, [isCartVisible, itemsInfos]);

  const completeOrder = async () => {
    setStatus('Processing');
    setToastMessageModal(true);
    setTypeOfMessage('processing');
    setMessage('Processing');
    const payload = {
      note: "",
      orderType: "WEB",
      mode: orderMode[0],
      redirectUrl: `${redirectUrl}/${slugName}`,
      totalTax: Number(splitUpInfo.tax),
      shopGid: shopGid,
      totalTip: Number(splitUpInfo.tipAmount),
      total: Number(splitUpInfo.netPayable),
      inventoryOrderLineItems: itemsInfos,
      orderDiscountData: null,
      promoCode: promoCode ? promoCode : null,
      orderDiscountAmount: Number(splitUpInfo.orderDiscount),
    };
    try {
      const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.GET_INVENTORY_ORDER, payload);
      window.location = entity.inventoryOrderLink.link;
    } catch (e) {
      if ((e.response.data.responseCode===412)&&(e.response && e.response.data.message.includes("Not enough stock is available to place item"))) {
        setStatus('Failed');
        setMessage('Notenoughstock');
        setTypeOfMessage('');
        setDisplayMessage(e.response.data.message);
        return false;
      } 
      console.error(e);
      setStatus('Failed');
      setMessage('Failed');
      setTypeOfMessage('');
      // getCartVisible(true);
    } finally {
      setTimeout(() => {
        setStatus('');
      }, 1000);
    }
  };

  const paymentAtStore = async (data) => {
    setStatus('Processing');
    setToastMessageModal(true);
    setTypeOfMessage('processing');
    setMessage('Processing');
    setIsProfileVisible(false);
    const payload = {
      mode: orderMode[0],
      payAtStore:true,
      customer: data,
      note: "",
      orderType: "WEB",
      redirectUrl: `${redirectUrl}/${slugName}`,
      shopGid: shopGid,
      totalTip: 0,
      totalTax: Number(splitUpInfo.tax),
      total: Number(splitUpInfo.netPayable),
      inventoryOrderLineItems: itemsInfos,
      promoCode: promoCode ? promoCode : null,
      orderDiscountAmount: Number(splitUpInfo.orderDiscount),
    };
    try {
      const { data: { entity } } = await axiosAuthInstance.post(API_END_POINTS.GET_INVENTORY_ORDER, payload);
      const inventOrderGid = entity && entity.gid;
      if (inventOrderGid) {
        history.push(`${PAGES.RECEIPT}/${slugName}?inventId=${inventOrderGid}`);
      }
    } catch (e) {
      console.error(e);
      setStatus('Failed');
      setMessage('Failed');
      setTypeOfMessage('');
    } finally {
      setTimeout(() => {
        setStatus('');
      }, 1000);
    }
  }

  const payWithSwirepay = () => {
    setIsPlacedOrder(false);
    setIsOrderPlaced(true);
    setPromoMessage('');
    getSplitUpInfo();
  };

  const updateModifiers = (idInfo) => {
    setIsModifierVisible(!isModifierVisible);
  };

  const searchItem = async (searchTerm) => {
    setSearchValue(searchTerm);
    if (searchTerm) {
      setSearchItems(true);
      try {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.GET_ITEMS_DETAILS}/${selectedShop}?name.LIKE=${searchTerm}`);
        getSearchItems(entity);
      } catch (e) {
        console.error(e);
      }
    } else {
      getSearchItems([]);
      setSearchItems(false);
    }

  };

  const scrollFunc = (id) => {
    const index = catList.findIndex(p => p.inventoryCategory.gid === id);
    const tempItem = document.getElementById(id);
    setItemKey(id);
    if (!fix) {
      window.scrollTo({
        top: tempItem.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: tempItem.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }

  };

  const updateSelectedMenu = (menu) => {
    setSelectedMenu(menu);
    // setAllCatItems(menu.categoryItems);
    setCatList(menu.categoryItems);
  };

  const updateSelectedMenuMbl = (menu) => {
    setOpenPanels([]);
    setSelectedMenu(menu);
    setCatList(menu.categoryItems);
  };

  const menuCheck = () => {
    if (openPanels.includes('1')) {
      setOpenPanels([]);
    } else {
      setOpenPanels(['1']);
    } 
  }

  const getSortOperating = (timings) => {
    const selectedTimeZone = (selectedAccount && selectedAccount.timezone) ? selectedAccount.timezone : localStorage.getItem('timeZone');
    const today = moment.utc(new Date()).tz(selectedTimeZone).format('MMM DD, YYYY, hh:mm a');
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    const currentDay = timings && timings.filter((weekDay) => weekDay.dayOfWeek === day);
    if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length > 1) {
      const tempArray = [];
      for (let i = 0; i < currentDay[0].operatingHours.length; i += 1) {
        if (currentDay[0].operatingHours[i].openingTime < minutes && currentDay[0].operatingHours[i].closingTime >= minutes && currentDay[0].operatingHours[i].open) {
          tempArray.push(true);
        }
      }
      if (tempArray && tempArray.length !== 0) {
        return true;
      } else if (tempArray && tempArray.length === 0) {
        return false;
      }
    } else if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length === 1) {
      if (currentDay[0].operatingHours[0].openingTime < minutes && currentDay[0].operatingHours[0].closingTime >= minutes && currentDay[0].operatingHours[0].open) {
        return true;
      } else {
        return false;
      }
    }
  }

  const getStoreOPen = (timings) => {
    const selectedTimeZone = (selectedAccount && selectedAccount.timezone) ? selectedAccount.timezone : localStorage.getItem('timeZone');
    const today = moment.utc(new Date()).tz(selectedTimeZone).format('MMM DD, YYYY, hh:mm a');
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    const currentDay = timings && timings.filter((weekDay) => weekDay.dayOfWeek === day);
    if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length > 1) {
      const tempArray = [];
      for (let i = 0; i < currentDay[0].operatingHours.length; i += 1) {
        if (currentDay[0].operatingHours[i].openingTime < minutes && currentDay[0].operatingHours[i].closingTime >= minutes && currentDay[0].operatingHours[i].open) {
          tempArray.push(true);
        }
      }
      if (tempArray && tempArray.length !== 0) {
        return true;
      } else if (tempArray && tempArray.length === 0) {
        return false;
      }
    } else if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length === 1) {
      if (currentDay[0].operatingHours[0].open) {
        return true;
      } else if (!currentDay[0].operatingHours[0].open) {
        return false;
      }
    }
  };

  const getStoreTimings = (timings) => {
    const today = moment.utc(new Date()).tz(selectedAccount && selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    const currentDay = timings && timings.filter((weekDay) => weekDay.dayOfWeek === day);
    if (currentDay && currentDay[0] && currentDay[0].operatingHours && currentDay[0].operatingHours.length > 1) {
      const array = [...currentDay[0].operatingHours];
      const tempArray = [];
      for (let i = 0; i < array.length; i += 1) {
        if (array[i].openingTime < minutes && array[i].closingTime >= minutes) {
          tempArray.push(true);
        }
      }
      tempArray.filter((item) => item === true);
      if (tempArray && tempArray.length !== 0) {
        return true;
      } else {
        return false;
      }
    }
      return currentDay && currentDay[0] && currentDay[0].operatingHours[0] &&
      currentDay[0].operatingHours[0].openingTime < minutes &&
      currentDay[0].operatingHours[0].closingTime >= minutes
  };

  const getCurrentDay = (timings) => {
    const today = moment.utc(new Date()).tz(selectedAccount && selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    return timings && timings.filter((weekDay) => weekDay.dayOfWeek === day);
  };

  const getCategoryAvailable = (timings) => {
    const today = moment.utc(new Date()).tz(selectedAccount && selectedAccount.timezone).format('MMM DD, YYYY, hh:mm a');
    const days = [
      "SUNDAY",
      "MONDAY",
      "TUESDAY",
      "WEDNESDAY",
      "THURSDAY",
      "FRIDAY",
      "SATURDAY",
    ];
    const day = days[new Date(today).getDay()];
    const minutes = new Date(today).getHours() * 60 + new Date(today).getMinutes();
    const currentDay = timings && timings.filter((weekDay) => weekDay.dayOfWeek === day);
    if (currentDay && currentDay[0].operatingHours && currentDay[0].operatingHours.length > 1) {
      const tempArray = [];
      for (let i = 0; i < currentDay[0].operatingHours.length; i += 1) {
        if (currentDay[0].operatingHours[i].openingTime < minutes && currentDay[0].operatingHours[i].closingTime >= minutes) {
          tempArray.push(true);
        }
      }
      if (tempArray && tempArray.length !== 0) {
        return true;
      } else if (tempArray && tempArray.length === 0) {
        return false;
      }
    } else if (currentDay && currentDay[0].operatingHours && currentDay[0].operatingHours.length === 1) {
      if (currentDay[0].operatingHours[0].open) {
        return currentDay[0].operatingHours[0].openingTime < minutes && currentDay[0].operatingHours[0].closingTime >= minutes;
      } else if (!currentDay[0].operatingHours[0].open) {
        return false;
      }
    }
  }

  const checkavailabilitySearch = (item) => {
    if (item && item.categories && item.categories.length !== 0) {
      const searchAvail = [];
      for (let i = 0; i < item.categories.length; i += 1) {
        if (item.categories[i].operatingHoursMaster && item.categories[i].enableHoursOfOperation) {
          if (item.categories[i].hoursOfOperations && item.categories[i].hoursOfOperations.length !== 0 && getCategoryAvailable(item.categories[i].hoursOfOperations)) {
            searchAvail.push(true);
          } else {
            searchAvail.push(false);
          }
        } else {
          searchAvail.push(true);
        }
      }
      const finalSearch = searchAvail.filter((item) => item === true);
      if (finalSearch && finalSearch.length !== 0) {
        updateItem(item);
      } else if (finalSearch && finalSearch.length === 0) {
        setUnavailableModal(true);
      }
    } else {
      setUnavailableModal(true);
    }
  };

  const checkavailability = (item, items) => {
    if (menusList && menusList.length !== 0) {
      if (selectedMenu.inventoryMenu.operatingHoursMaster && selectedMenu.inventoryMenu.operatingHoursMaster.hoursOfOperations && getStoreOPen(selectedMenu.inventoryMenu.operatingHoursMaster.hoursOfOperations)) {
        if (getStoreTimings(selectedMenu.inventoryMenu.operatingHoursMaster.hoursOfOperations)) {
          if (item.inventoryCategory.operatingHoursMaster && item.inventoryCategory.enableHoursOfOperation) {
            if (item.inventoryCategory.hoursOfOperations && item.inventoryCategory.hoursOfOperations.length !== 0 && getCategoryAvailable(item.inventoryCategory.hoursOfOperations)) {
              updateItem(items);
            } else {
              setUnavailableModal(true);
            }
          } else {
            updateItem(items);
          }
        } else {
          setUnavailableModal(true);
        }
      } else {
        setUnavailableModal(true);
      }
    } else if (menusList && menusList.length === 0) {
      if (item.inventoryCategory.operatingHoursMaster && item.inventoryCategory.enableHoursOfOperation) {
        if (item.inventoryCategory.hoursOfOperations && item.inventoryCategory.hoursOfOperations.length !== 0 && getCategoryAvailable(item.inventoryCategory.hoursOfOperations)) {
          updateItem(items);
        } else {
          setUnavailableModal(true);
        }
      } else {
        updateItem(items);
      }
    }   
  };

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 12) {
        return '12:00 PM';
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  const getNextAvailability = (data) => {
    if (data) {
      const days = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ];
      const day = days[new Date(data).getDay()];
      const minutes = new Date(data).getHours() * 60 + new Date(data).getMinutes();
      return `Next Available: ${day}, ${getOperatingTime(minutes)}`;
    } else {
      return '';
    } 
  }

  const shopUpdate = () => {
    setSlectedShop(shopGid);
    setSearchItems(false);
    setSearchValue('');
    getSearchItems([]);
    localStorage.setItem('selectedShop', shopGid);
    setStatus('');
    setToastMessageModal(false);
    setTypeOfMessage('');
    setMessage('');
    setSelectedShopInfo(selectedShopInfoTemp);
    setSelectedShopInfoTemp({});
  }

  const exstingShop = () => {
    setStatus('');
    setToastMessageModal(false);
    setTypeOfMessage('');
    setMessage('');
    setSelectedShopInfoTemp({});
  }

  const handleScrollLeft = () => {
    if (scrollPosition < 0) {
      setScrollPosition(scrollPosition + 100);
    }
  };
  const handleScrollRight = () => {
    setScrollPosition(scrollPosition - 100);
  };

  const validateFunc = (items) => {
    setCatList(items);
  };

  useEffect(() => {
    if (selectedShopInfoTemp && selectedShopInfoTemp.gid) {
      setStatus('updateShop');
      setToastMessageModal(true);
      setTypeOfMessage('Updateshop');
      setMessage('Updateshop');
    }
  }, [selectedShopInfoTemp]);

  const getStockAvailability = (items) => {
    if (items.available) {
      if (!items.enableStock) {
        return false;
      } else if (items.enableStock && !items.enableDynamicAvailability && items.stockQuantity !== 0) {
        return false;
      } else if (items.enableStock && !items.enableDynamicAvailability && items.stockQuantity === 0) {
        return true;
      } else if (items.enableStock && items.enableDynamicAvailability && items.stockQuantity === 0) {
        return true;
      } else if (items.enableStock && items.enableDynamicAvailability && items.stockQuantity !== 0) {
        return false;
      }
    } else {
      return true;
    } 
  }

  if (loading) {
    return <Loading />;
  }

  const tabContent = (name, key) => {
    return (
      <Row>
        {
          itemsInfo && itemsInfo.length !== 0 && (
            <>
              {
                !searchActive && isMobileView ? (
                  <div
                    className="categorieNavigation"
                    style={{ visibility: "visible" }}
                  >
                    <div className="menu" style={{ scrollBehavior: "smooth" }}>
                      <div
                        className="menuListCat"
                        style={{ transform: `translateX(${scrollPosition}px)` }}
                      >
                        {inventoryInfo &&
                          inventoryInfo.map((item) => (
                            <>
                              <li className="menuListCatItem">
                                <div className="text-center" style={{ marginRight: '30px', borderBottom: item.gid === itemKey ? '3px solid #00378C' : '', paddingBottom: '10px', cursor: 'pointer' }} onClick={() => callback(item)}>
                                  <SpText color={item.gid === itemKey ? '#00378C' : "#393939"} fontSize="16px" fontWeight="600">{item.name}</SpText>
                                </div>
                              </li>
                            </>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )
              }
              <>
                {
                  itemsInfo.map((items) => (
                    <Col span={isMobileView ? 24 : 6} className="mt-3">
                      <div
                        style={{
                          display: 'flex',
                          width: '95%',
                          minHeight: isMobileView ? '100px' : '150px',
                          border: '2px solid #F3F3F3',
                          cursor: 'pointer',
                          borderRadius: '20px',
                        }}
                        onClick={() => {(isShopOpen && slugName !== 'a2bplmenu') ? (getStockAvailability(items) ? '' : checkavailabilitySearch(items)) : '' }}
                      >
                        {
                          getStockAvailability(items) ? (
                            <div
                              style={{
                                backgroundColor: '#ff4b51',
                                color: '#FFF',
                                position: 'absolute',
                                padding: '5px 10px',
                                fontWeight: '600',
                                top: '20px',
                              }}
                            >
                              Out of stock
                            </div>
                          ) : (
                            <></>
                          )
                        }
                        <div
                          style={{
                            width: items.imageUrl ? '30%' : '0%',
                            backgroundImage: `url(${items.imageUrl ? items.imageUrl : ''})`,
                            backgroundSize: '100%',
                            backgroundRepeat: 'no-repeat',
                            borderRadius: '20px 0px 0px 20px',
                            backgroundPosition: 'center',
                          }}
                        >
                          &nbsp;
                        </div>
                        <div
                          style={{
                            width: items.imageUrl ? '70%' : '100%',
                            padding: '15px'
                          }}
                        >
                          <Row type="flex" justify="space-between" align="start">
                            <Col span={24} className="d-flex justify-content-start align-items-start">
                              <SpText fontSize="16px" color="#000000" fontWeight="700" style={{ overflowWrap: 'break-word' }}>{items.name}</SpText>
                            </Col>
                            <Col span={24}>
                              <SpText fontSize="12px" color="#5C5C5C" fontWeight="500" style={{ overflowWrap: 'break-word' }}>{items.description ? items.description : ''}</SpText>
                            </Col>
                            <Col span={24} className="justify-content-end align-items-start">
                              {
                                items.coupon && items.coupon ? (
                                  <>
                                    {
                                      items.coupon.percentageOff ? (
                                        <>
                                          <div>
                                            <SpText fontSize="16px" color="#000000" fontWeight="400">$ {(items.price / 100).toFixed(2)}</SpText>
                                          </div>
                                          <div>
                                            <SpText fontSize="16px" color="#FFF" fontWeight="400" style={{ backgroundColor: '#FC1D56', borderRadius: '50px', padding: '0 10px' }}>% {items.coupon.percentageOff} Off</SpText>
                                          </div>

                                        </>
                                      ) : (
                                        <>
                                          <div>
                                            <SpText fontSize="16px" color="#000000" fontWeight="400">$ {(items.price / 100).toFixed(2)}</SpText>
                                          </div>
                                          <div>
                                            <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {(items.coupon.amountOff / 100).toFixed(2)} Off</SpText>
                                          </div>

                                        </>
                                      )
                                    }

                                  </>
                                ) : (
                                  <SpText fontSize="16px" color="#000000" fontWeight="400">$ {(items.price / 100).toFixed(2)}</SpText>
                                )
                              }

                            </Col>
                          </Row>

                        </div>

                      </div>
                    </Col>
                  ))
                }
              </>
            </>
          )
        }
      </Row>
    )
  };

  
  const selectedMenuInfo = (menu) => {
    return (
      <>
        <div>
          <SpText fontSize="18px" color={getStoreOPen(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (getStoreTimings(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? "#000000" : "#FF4B51") : "#FF4B51"} fontWeight="600">{menu.inventoryMenu.name}</SpText>
          {
            menu && menu.inventoryMenu && menu.inventoryMenu.coupon ? (
              <>
                {
                  menu.inventoryMenu.coupon.percentageOff ? (
                    <div>
                      <SpText fontSize="16px" color="#FFF" fontWeight="400" style={{ backgroundColor: '#00BA3F', borderRadius: '50px', padding: '0 10px' }}>% {menu.inventoryMenu.coupon.percentageOff} Off</SpText>
                    </div>
                  ) : (
                    <div>
                      <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {(menu.inventoryMenu.coupon.amountOff / 100).toFixed(2)} Off</SpText>
                    </div>
                  )
                }
              </>
            ) : (
              <></>
            )
          }
        </div>
        <>
          {
            getStoreOPen(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
              <>
                {
                  getStoreTimings(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
                    <></>
                  ) : (
                    <>
                      <div>
                        <SpText fontSize="14px" color="#FF4B51" fontWeight="600">(Currently Unavailable)</SpText>
                      </div>
                      <div>
                      {<SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getNextAvailability(menu.inventoryMenu.NextAvailable)}</SpText>}
                    </div>
                      {/* <div>
                        {
                          getCurrentDay(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations)[0].operatingHours.map((time) => (
                            <>
                              <div>
                                <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.openingTime)}</SpText>
                                <SpText fontSize="14px" color="#FF4B51" fontWeight="400">&nbsp;To&nbsp;</SpText>
                                <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.closingTime)}&nbsp;,</SpText>
                              </div>
                            </>
                          ))
                        }
                      </div> */}
                    </>
                  )
                }
              </>
            ) : (
              <>
                <div>
                  <SpText fontSize="14px" color="#FF4B51" fontWeight="600">(Currently Unavailable)</SpText>
                </div>
                  <div>
                    {<SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getNextAvailability(menu.inventoryMenu.NextAvailable)}</SpText>}
                  </div>
                {/* <div>
                  {
                    getCurrentDay(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations)[0].operatingHours.map((time) => (
                      <>
                        <div>
                          <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.openingTime)}</SpText>
                          <SpText fontSize="14px" color="#FF4B51" fontWeight="400">&nbsp;To&nbsp;</SpText>
                          <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.closingTime)}&nbsp;,</SpText>
                        </div>
                      </>
                    ))
                  }
                </div> */}
              </>
            )
          }
        </>
        {
          menu.inventoryMenu.operatingHoursMaster && menu.inventoryMenu.operatingHoursMaster.hoursOfOperations && getStoreOPen(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
            <>
              {
                getStoreTimings(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
                  <>
                    {
                      getCurrentDay(menu.inventoryMenu.operatingHoursMaster.hoursOfOperations)[0].operatingHours.map((time) => (
                        <>
                          {
                            time.open ? (
                              <div>
                                <span>{getOperatingTime(time.openingTime)}</span>
                                <span>&nbsp;To&nbsp;</span>
                                <span>{getOperatingTime(time.closingTime)}&nbsp;,</span>
                              </div>
                            ) : (
                              <></>
                            )
                          }
                        </>
                      ))
                    }
                  </>
                ) : (
                  <></>
                )
              }
            </>
          ) : (
            <></>
          )
        }
      </>
    );
  };

  return (
    <Card className="my-3 category-list" >
      <div id="snackbar">Your cart is updated...</div>
      {
        !mblCat && isMobileView && (
          <div
            onClick={() => setMblCat(true)} className={`cat-ham-menu ${isFloating ? 'cat-ham-menu_fixed' : ''}`}
            style={{

            }}>
            <Row type="flex" justify="space-between">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                <img src={Chart} />
              </Col>
            </Row>
          </div>
        )
      }

      {/* <div style={{ padding: isMobileView ? '0px 10px' : '10px 60px' }}>
        <div>
          <label htmlFor="">
            <SpText fontSize="18px" fontWeight="600" >Select Shop</SpText>
          </label>
        </div>
        <Select
          style={{ width: isMobileView ? "100%" : "20%" }}
          value={selectedShop}
          onChange={updateShop}
        >
          {
            shopList
              .map((item) => (
                <Option key={item.gid} value={item.gid}>
                  {item.name}
                </Option>
              ))
          }
        </Select>
      </div> */}

      {
        isMobileView && !searchActive && menusList && menusList.length !== 0 && selectedMenu ? (
          <div>
            <Collapse onChange={menuCheck} expandIconPosition='end' activeKey={openPanels}>
              <Panel header={selectedMenuInfo(selectedMenu)} key='1'>
                {
                  menusList.map((menu) => (
                    <div style={{ borderBottom: '1px solid #e7e4e4', padding: '10px 0px', cursor: 'pointer' }} onClick={() => updateSelectedMenuMbl(menu)}>
                      {selectedMenuInfo(menu)}
                    </div>
                  ))
                }
              </Panel>
            </Collapse>
          </div>
        ) : (
          <></>
        )
      }



      <div style={{ padding: isMobileView ? '0px 10px' : '0px 60px', minHeight: '600px' }}>
      {
          !isMobileView && !searchActive && menusList && menusList.length !== 0 ? (
            <div className="d-flex" style={{ overflowX: 'scroll', backgroundColor: '#F5F5F5', borderRadius: '10px' }}>
              {
                menusList.map((item) => (
                  <div
                    className="mr-2"
                    style={{
                      marginRight: '10px',
                      minWidth: '200px',
                      padding: '15px 10px',
                      cursor: 'pointer',
                      borderBottom: item.inventoryMenu.gid === (selectedMenu && selectedMenu.inventoryMenu.gid) ? '3px solid #000' : '',
                    }}
                    onClick={() => updateSelectedMenu(item)}
                  >
                    {
                      item.inventoryMenu.operatingHoursMaster && item.inventoryMenu.operatingHoursMaster.gid ? (
                        <div>
                          <SpText fontSize="18px" color={getStoreOPen(item.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (getStoreTimings(item.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? "#000000" : "#FF4B51") : "#FF4B51"} fontWeight="600">{item.inventoryMenu.name}</SpText>
                          {
                                item && item.inventoryMenu && item.inventoryMenu.coupon ? (
                                  <>
                                    {
                                      item.inventoryMenu.coupon.percentageOff ? (
                                        <div>
                                        <SpText fontSize="16px" color="#FFF" fontWeight="400" style={{ backgroundColor: '#00BA3F', borderRadius: '50px', padding: '0 10px' }}>% {item.inventoryMenu.coupon.percentageOff} Off</SpText>
                                        </div>
                                      ) : (
                                        <div>
                                        <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {(item.inventoryMenu.coupon.amountOff / 100).toFixed(2)} Off</SpText>
                                        </div>
                                      )
                                    }
                                  </>
                                ) : (
                                  <></>
                                )
                              }
                        </div>
                      ) : (
                        <></>
                      )
                    }
                    
                    <>
                    {
                      item.inventoryMenu.operatingHoursMaster && item.inventoryMenu.operatingHoursMaster.gid && getStoreOPen(item.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
                        <>
                        {
                          getStoreTimings(item.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
                            <></>
                          ) : (
                            <>
                            <div>
                             <SpText fontSize="14px" color="#FF4B51" fontWeight="600">(Currently Unavailable)</SpText>
                            </div>
                                    <div>
                                      {<SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getNextAvailability(item.inventoryMenu.NextAvailable)}</SpText>}
                                    </div>
                            {/* <div>
                            {
                              getCurrentDay(item.inventoryMenu.operatingHoursMaster.hoursOfOperations)[0].operatingHours.map((time) => (
                                <>
                                  <div>
                                    <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.openingTime)}</SpText>
                                    <SpText fontSize="14px" color="#FF4B51" fontWeight="400">&nbsp;To&nbsp;</SpText>
                                    <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.closingTime)}&nbsp;,</SpText>
                                  </div>
                                </>
                              ))
                            }
                            </div> */}
                            </>
                          )
                        }
                        </>
                      ) : (
                            <>
                              <div>
                                <SpText fontSize="14px" color="#FF4B51" fontWeight="600">(Currently Unavailable)</SpText>
                              </div>
                              <div>
                                {<SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getNextAvailability(item.inventoryMenu.NextAvailable)}</SpText>}
                              </div>
                              {/* <div>
                                {
                                  item.inventoryMenu.operatingHoursMaster && item.inventoryMenu.operatingHoursMaster.gid && getCurrentDay(item.inventoryMenu.operatingHoursMaster.hoursOfOperations)[0].operatingHours.map((time) => (
                                    <>
                                      <div>
                                        <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.openingTime)}</SpText>
                                        <SpText fontSize="14px" color="#FF4B51" fontWeight="400">&nbsp;To&nbsp;</SpText>
                                        <SpText fontSize="14px" color="#FF4B51" fontWeight="400">{getOperatingTime(time.closingTime)}&nbsp;,</SpText>
                                      </div>
                                    </>
                                  ))
                                }
                              </div> */}
                            </>
                      )
                    }
                    </>
                    {
                      item.inventoryMenu.operatingHoursMaster && item.inventoryMenu.operatingHoursMaster.gid && getStoreOPen(item.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
                        <>
                        {
                          getStoreTimings(item.inventoryMenu.operatingHoursMaster.hoursOfOperations) ? (
                            <>
                            {
                              getCurrentDay(item.inventoryMenu.operatingHoursMaster.hoursOfOperations)[0].operatingHours.map((time) => (
                                <>
                                {
                                  time.open ? (
                                      <div>
                                        <span>{getOperatingTime(time.openingTime)}</span>
                                        <span>&nbsp;To&nbsp;</span>
                                        <span>{getOperatingTime(time.closingTime)}&nbsp;,</span>
                                      </div>
                                  ) : (
                                    <></>
                                  )
                                }
                                </>
                              ))
                            }
                            </>
                          ) : (
                            <></>
                          )
                        }
                        </>
                      ) : (
                        <></>
                      )
                    }
                  </div>
                ))
              }
            </div>
          ) : (
            <></>
          )
        }
        <div className={fix ? "sticky" : ""} style={{ visibility: "visible", top: "0px", background: "#ffffff", }}>
          {
            !searchActive && allCatItems && allCatItems.length !== 0 && (
              <>
              <div className="panner bounce-left" onClick={handleScrollLeft} style={{ top: isMobileView ? (fix ? '5px' : (menusList && menusList.length !== 0 ? '94px' : '5px')) : (fix ? '15px' : (menusList && menusList.length !== 0 ? '125px' : '15px')) }} >
                  <i style={{ backgroundImage: `url(${ArrImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}} />
                </div>

                <div className="panner bounce-right" onClick={handleScrollRight} style={{ top: isMobileView ? (fix ? '5px' : (menusList && menusList.length !== 0 ? '94px' : '5px')) : (fix ? '15px' : (menusList && menusList.length !== 0 ? '125px' : '15px')) }} >
                  <i style={{ backgroundImage: `url(${ArrImg})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }} />
                </div>
                {
                  menusList && menusList.length !== 0 ? (
                    <NavBar navHeader={catList.length !== 0 ? catList : []} isMobileView={isMobileView} scrollFunc={scrollFunc} validateFunc={validateFunc} testing={allCatItems} menusList={menusList} scrollPosition={scrollPosition} />
                  ) : (
                      <NavBarMenu
                        navHeader={catList.length !== 0 ? catList : []}
                        testing={allCatItems}
                        validateFunc={validateFunc}
                        isMobileView={isMobileView}
                        scrollFunc={scrollFunc}
                        scrollPosition={scrollPosition}
                      />
                  )
                }
              </>
            )
          }
          {
            isMobileView && (
              <Row type="flex" justify="end" style={{ marginTop: '10px' }}>
                <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                  <Search placeholder="Search store menu" value={searchValue} allowClear onChange={(e) => searchItem(e.currentTarget.value)} />
                </Col>
              </Row>
            )
          }
        </div>

        <div onScroll={() => console.log(123)}>

          {
            !searchActive && catList.length !== 0 && catList.map((item) => (
              <div data-section className="section" ref={item.inventoryCategory.headerRef} id={item.inventoryCategory.gid}>
                <div style={{ marginRight: '30px', padding: '10px 0 5px 0' }}>
                  <SpText color="#000000" fontSize="18px" fontWeight="600">{item.inventoryCategory.name}</SpText>
                </div>
                <Row>
                  {
                    item.items.map((items) => (
                      <Col span={isMobileView ? 24 : 6} className="mt-3">
                        <div
                          style={{
                            display: 'flex',
                            width: '95%',
                            minHeight: isMobileView ? '100px' : '150px',
                            border: '1px solid #C3C3C3',
                            cursor: 'pointer',
                            borderRadius: '20px',
                            background: '#FFFFFF'
                          }}
                          onClick={() => {(isShopOpen && slugName !== 'a2bplmenu') ? (getStockAvailability(items) ? '' : checkavailability(item, items)) : '' }}
                        >
                          {
                            getStockAvailability(items) ? (
                              <div
                                style={{
                                  backgroundColor: '#ff4b51',
                                  color: '#FFF',
                                  position: 'absolute',
                                  padding: '5px 10px',
                                  fontWeight: '600',
                                  top: '20px',
                                }}
                              >
                                Out of stock
                              </div>
                            ) : (
                              <></>
                            )
                          }
                          <div
                            style={{
                              width: items.imageUrl ? '30%' : '0%',
                              backgroundImage: `url(${items.imageUrl})`,
                              backgroundSize: '100%',
                              backgroundRepeat: 'no-repeat',
                              borderRadius: '20px 0px 0px 20px',
                              backgroundPosition: 'center',
                            }}
                          >
                            &nbsp;
                          </div>
                          <div
                            style={{
                              width: items.imageUrl ? '70%' : '100%',
                              padding: '15px'
                            }}
                          >
                            <Row type="flex" justify="space-between" align="start">
                              <Col span={24} className="d-flex justify-content-start align-items-start">
                                <SpText fontSize="16px" color="#000000" fontWeight="700" style={{ overflowWrap: 'break-word' }}>{items.onlineName && items.onlineName.length !==0 ? items.onlineName  : items.name}</SpText>
                              </Col>
                              <Col span={24}>
                                <SpText fontSize="12px" color="#5C5C5C" fontWeight="500" style={{ overflowWrap: 'break-word' }}>{items.description ? items.description : ''}</SpText>
                              </Col>
                              <Col span={24} className="justify-content-end align-items-start">
                                <div>
                                  <SpText fontSize="16px" color="#000000" fontWeight="400">$ {(items.price / 100).toFixed(2)}</SpText>
                                </div>
                                {
                                        items.coupon ? (
                                          <>
                                            {
                                              items.coupon.percentageOff ? (
                                                <div>
                                                  <SpText fontSize="16px" color="#FFF" fontWeight="400" style={{ backgroundColor: '#FC1D56', borderRadius: '50px', padding: '0 10px' }}>% {items.coupon.percentageOff} Off</SpText>
                                                </div>
                                              ) : (
                                                <div>
                                                  <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {(items.coupon.amountOff / 100).toFixed(2)} Off</SpText>
                                                </div>
                                              )
                                            }
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      }
                              </Col> 
                            </Row>
                          </div>

                        </div>
                      </Col>
                    ))
                  }
                </Row>
              </div>
            ))
          }
        </div>

        {
          searchActive && searchItems && searchItems.length !== 0 && (
            <Tabs>
              <TabPane>
                {tabContent(selectedInventoryOrder.name, selectedInventoryOrder.gid)}
              </TabPane>
            </Tabs>
          )
        }


      </div>
      {
        isAddItemVisible && (
          <AddItems
            close={() => setIsItemVisible(false)}
            visible={isAddItemVisible}
            submit={addItemsToCart}
            selectedItem={selectedItem}
            shopGid={selectedShop}
          />
        )
      }

      {
        isEditItemModal && (
          <EditItem
            close={() => setIsEditItemModal(false)}
            visible={isEditItemModal}
            submit={editItemsToCart}
            selectedItem={selectedItem}
            editItem={editItem}
            selectedSequence={selectedSequence}
            shopGid={selectedShop}
          />
        )
      }

      {
        isShopSelection && (
          <ShopSelection
            close={() => setIsShopSelection(false)}
            visible={isShopSelection}
          />
        )
      }

      {
        isProfileVisible && (
          <Profile
            close={() => setIsProfileVisible(false)}
            visible={isProfileVisible}
            submit={paymentAtStore}
          />
        )
      }

      {
        isPlacedOrder && (
          <PlaceOrder
            close={() => { setIsPlacedOrder(false), getCartVisible(false) }}
            visible={isPlacedOrder}
            payWithSwirepay={payWithSwirepay}
          />
        )
      }

      {
        toastMessageModal && (
          <ToastMessages
            close={() => setToastMessageModal(false)}
            visible={toastMessageModal}
            message={message}
            type={typeOfMessage}
            shopUpdate={shopUpdate}
            exstingShop={exstingShop}
            displayMessage={displayMessage}
          />
        )
      }

      {
        unavailableModal && (
          <Unavailable
            close={() => setUnavailableModal(false)}
            visible={unavailableModal}
          />
        )
      }

      {
        isMobileView && (
          <Modal
            width="unset"
            open={mblCat}
            className="cartModal1"
            onCancel={() => setMblCat(false)}
            footer={
              <Row className='mt-2 btn-catClose bg-white'>
                <Col span={24}>
                  <Button className='searchDateGradientBtn w-100' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => setMblCat(false)} disabled={disabled}>
                    Close
                  </Button>
                </Col>
              </Row>
            }>

            <Row type="flex" justify="space-between" align="middle">
              <Col span={12} className="d-flex justify-content-start align-items-center">
                &nbsp;
              </Col>
              <Col span={12} className="d-flex justify-content-end align-items-center">
                <img src={CloseIcon} height='30' width='30' onClick={() => setMblCat(false)} style={{ cursor: 'pointer' }} />
              </Col>
            </Row>

            <Row type="flex" justify="space-between" align="middle" className="mb-3">
              <Col span={24} className="d-flex justify-content-start align-items-center px-3 py-2">
                <SpText fontSize="20px" color="#E3007B" fontWeight="700">Categories</SpText>
              </Col>
            </Row>
            <div style={{ height: 'calc(100vh - 45vh)', overflowY: 'scroll' }}>
            {
                catList && catList.length !== 0 && (
                  <>
                    {
                      catList.map((item) => (
                        <>
                          <Row type="flex" justify="space-between" align="middle" className="mb-1" style={{ cursor: 'pointer' }} onClick={e => {
                            scrollFunc(item.inventoryCategory.gid);
                            setMblCat(false);
                          }} >
                            <Col span={24} className="d-flex justify-content-start align-items-center px-3 py-1">
                              <SpText fontSize="16px" color={item.inventoryCategory.gid === itemKey ? '#00378C' : "#393939"} fontWeight="600">{item.inventoryCategory.name}</SpText>
                            </Col>
                          </Row>
                          <hr />
                        </>
                      ))
                    }
                  </>
                )
              }
            </div>

          </Modal>
        )
      }

      <Modal
        width="unset"
        open={isCartVisible}
        className="cartModal"
        footer={null}
        onCancel={() => { setIsOrderPlaced(false), setTipPercentage() }}
      >
        <>
          {
            isOrderPlaced ? (
              <div style={{ backgroundColor: '#F3F3F3' }}>
                <div style={{ backgroundColor: '#fff' }}>
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={12} className="d-flex justify-content-start align-items-center">
                      <img src={Back} height='20' width='20' onClick={() => { setIsOrderPlaced(false), setTipPercentage(), setPromoCode('') }} style={{ cursor: 'pointer' }} />
                    </Col>
                    <Col span={12} className="d-flex justify-content-end align-items-center">
                      <img src={CloseIcon} height='20' width='20' onClick={() => getCartVisible(false)} style={{ cursor: 'pointer' }} />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                      <img src={localStorage.getItem('banner') ? localStorage.getItem('banner') : ''} width={isMobileView ? "100" : "100"} />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex justify-content-center align-items-center">
                      <SpText fontSize="20px" color="#E3007B" fontWeight="700">Pay Order</SpText>
                    </Col>
                  </Row>
                </div>
                <div style={{ height: isMobileView ? 'calc(73vh)' : 'calc(80vh)', overflowY: 'scroll' }}>
                  <div className="mx-2 my-2 mb-3 px-md-4 px-3 py-2" style={{ borderRadius: '10px', backgroundColor: '#fff' }}>
                    <Row type="flex" justify="space-between">
                      <Col className="justify-content-center align-items-center mb-2">
                        <SpText fontSize="16px" color="#000000" fontWeight="700">Your items</SpText>
                      </Col>
                    </Row>
                    {
                      itemsInfos.map((orders) => (
                        <>
                          <Row type="flex" justify="space-between">
                            <Col span={10} className="justify-content-center align-items-center mb-2">
                              <div>
                                <SpText fontSize="16px" color="#000000" fontWeight="600">{orders.onlineName && orders.onlineName.length !==0 ? orders.onlineName : orders.name}</SpText>
                              </div>
                              {
                                orders.discountedPrice ? (
                                  <div>
                                    <SpText fontSize="14px" color="#000000" fontWeight="400">$ {(orders.discountedPrice / 100).toFixed(2)}</SpText>
                                  </div>
                                ) : (
                                  <div>
                                    {
                                      orders.price > 0 ? (
                                        <SpText fontSize="14px" color="#000000" fontWeight="400">$ {(orders.price / 100).toFixed(2)}</SpText>
                                      ) : (
                                        <></>
                                      )
                                    }
                                  </div>
                                )
                              }
                            </Col>
                            <Col span={4} className="justify-content-start align-items-center mb-2" style={{ textAlign: 'center' }}>

                              <SpText fontSize="22px" color='#000000' fontWeight='500' style={{ cursor: 'pointer', padding: '0px 10px' }}>&nbsp;</SpText>
                              <SpText fontSize="18px" color='#000000' fontWeight='500' style={{ padding: '0px 10px' }}>&nbsp;</SpText>

                              <SpText fontSize="22px" color='#000000' fontWeight='500' style={{ cursor: 'pointer', padding: '0px 10px' }}>&nbsp;</SpText>
                            </Col>
                            <Col span={10} className="justify-content-end align-items-center mb-2 px-3" style={{ textAlign: 'center' }}>
                              <div className="d-flex justify-content-end">
                                <SpText fontSize="16px" color="#000000" fontWeight="700">$ {((orders.price) / 100).toFixed(2)}</SpText>
                              </div>
                              <div className="d-flex justify-content-end">
                                <SpText fontSize="14px" color="#757575" fontWeight="400">x{orders.quantity}</SpText>
                              </div>
                            </Col>
                            {
                              orders.lineItemModifiers && orders.lineItemModifiers.length !== 0 && (
                                <>
                                  {
                                    orders.lineItemModifiers.map((item) => (
                                      <>
                                        <Col span={24} className="justify-content-center align-items-center mb-2">
                                          <Row>
                                            <Col span={24} className="justify-content-center align-items-center mb-2">
                                              <div>
                                                {
                                                  item.price > 0 ? (
                                                    <SpText fontSize="16px" color="#5C5C5C" fontWeight="500">{orders.isExst ? item.modifierName : item.name} x{item.quantity} (+ $ {(((item.price ? item.price : 0) * item.quantity) / 100).toFixed(2)})</SpText>
                                                  ) : (
                                                    <SpText fontSize="16px" color="#5C5C5C" fontWeight="500">{orders.isExst ? item.modifierName : item.name} x{item.quantity}</SpText>
                                                  )
                                                }
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>

                                      </>
                                    ))
                                  }
                                </>
                              )
                            }
                          </Row>
                          <hr />
                        </>
                      ))
                    }
                    <Row type="flex" justify="space-between">
                      <Col span={24} className="justify-content-center align-items-center mb-2">
                        <SpText fontSize="16px" color="#000000" fontWeight="400" style={{ cursor: 'pointer' }} >Add Promo Code</SpText>
                      </Col>
                      <Col span={24} className="justify-content-center align-items-center mb-2">
                        <Row gutter={5} type="flex" justify="space-between">
                          <Col span={18}>
                            <Input value={promoCode} type="text" placeholder="Enter Code" style={{ marginRight: '2%', width: '100%', borderRadius: '6px' }} onChange={(e) => { setPromoCode((e.currentTarget.value).toUpperCase()), setPromoMessage('') }} />
                          </Col>
                          <Col span={6}>
                            <Button style={{ borderRadius: '10px', border: '1px solid #AEAEAE', background: '#FFFFFF' }} className='searchDateGradientBtn1 w-20' onClick={() => getSplitWithPromo()}>Apply</Button>
                          </Col>
                        </Row>
                      </Col>
                      {
                        promoCode && promoMessage ? (
                          <Col span={24} className="justify-content-center align-items-center mb-2">
                            <SpText fontSize='16px' color={promoMessage === 'Promo-Code applied' ? '#00BA4A' : '#FF0000'}>{promoMessage}</SpText>
                          </Col>
                        ) : (
                          <></>
                        )
                      }
                    </Row>
                    <hr />
                    <div>
                    </div>
                    <Row type="flex" justify="space-between">
                      <Col span={24} className="justify-content-center align-items-center mb-2">
                        <SpText fontSize="20px" color="#000000" fontWeight="700" >Bill details</SpText>
                      </Col>
                    </Row>
                    <Row type="flex" justify="space-between">
                      <Col span={12} className="justify-content-center align-items-center mb-2">
                        <SpText fontSize="16px" color="#000000" fontWeight="400" >Sub total</SpText>
                      </Col>
                      <Col span={12} className="justify-content-center align-items-center mb-2" style={{ textAlign: 'right', paddingRight: '25px' }} >
                        <SpText fontSize="16px" color="#000000" fontWeight="400" >$ {splitUpInfo && splitUpInfo.subTotal && (splitUpInfo.subTotal / 100).toFixed(2)}</SpText>
                      </Col>
                    </Row>
                    {
                      splitUpInfo && splitUpInfo.orderDiscount ? (
                        <Row type="flex" justify="space-between">
                          <Col span={12} className="justify-content-center align-items-center mb-2">
                            <SpText fontSize="16px" color="#000000" fontWeight="400" >Discount</SpText>
                          </Col>
                          <Col span={12} className="justify-content-center align-items-center mb-2" style={{ textAlign: 'right', paddingRight: '25px' }}>
                            <SpText fontSize="16px" color="#FF0000" fontWeight="400" >$ {splitUpInfo && splitUpInfo.orderDiscount && (splitUpInfo.orderDiscount / 100).toFixed(2)}</SpText>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )
                    }
                    {
                      splitUpInfo && splitUpInfo.serviceFee ? (
                        <Row type="flex" justify="space-between">
                          <Col span={12} className="justify-content-center align-items-center mb-2">
                            <SpText fontSize="16px" color="#000000" fontWeight="400" >Service Charge</SpText>
                          </Col>
                          <Col span={12} className="justify-content-center align-items-center mb-2" style={{ textAlign: 'right', paddingRight: '25px' }}>
                            <SpText fontSize="16px" color="#000000" fontWeight="400" >$ {splitUpInfo && splitUpInfo.serviceFee && (splitUpInfo.serviceFee / 100).toFixed(2)}</SpText>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )
                    }
                    {
                      splitUpInfo && splitUpInfo.tax ? (
                        <Row type="flex" justify="space-between">
                          <Col span={12} className="justify-content-center align-items-center mb-2">
                            <SpText fontSize="16px" color="#000000" fontWeight="400" >Taxes</SpText>
                          </Col>
                          <Col span={12} className="justify-content-center align-items-center mb-2" style={{ textAlign: 'right', paddingRight: '25px' }}>
                            <SpText fontSize="16px" color="#000000" fontWeight="400" >$ {splitUpInfo && splitUpInfo.tax && (splitUpInfo.tax / 100).toFixed(2)}</SpText>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )
                    }
                    {
                    splitUpInfo && splitUpInfo.tipAmount ? (
                      <Row type="flex" justify="space-between">
                        <Col span={12} className="justify-content-center align-items-center mb-2">
                          <SpText fontSize="16px" color="#000000" fontWeight="400" >Tip</SpText>
                        </Col>
                        <Col span={12} className="justify-content-center align-items-center mb-2" style={{textAlign:'right',paddingRight:'25px'}}>
                          <SpText fontSize="16px" color="#000000" fontWeight="400" >$ {splitUpInfo && splitUpInfo.tipAmount && (splitUpInfo.tipAmount / 100).toFixed(2)}</SpText>
                        </Col>
                      </Row>
                    ) : (
                      <Row type="flex" justify="space-between">
                        <Col span={12} className="justify-content-center align-items-center mb-2">
                          <SpText fontSize="16px" color="#000000" fontWeight="400" >Tip</SpText>
                        </Col>
                        <Col span={12} className="justify-content-center align-items-center mb-2" style={{textAlign:'right',paddingRight:'25px'}}>
                          <SpText fontSize="16px" color="#000000" fontWeight="400" >$ 0.00</SpText>
                        </Col>
                      </Row>
                    )
                  }
                    <Row type="flex" justify="space-between">
                      {/* <Col span={24} className="justify-content-center align-items-center mb-2">
                        <SpText fontSize="16px" color="#5C5C5C" fontWeight="700" style={{ cursor: 'pointer' }} >Tips</SpText>
                      </Col> */}
                 
                       <Col span={24} className="justify-content-center align-items-center mb-2">
                        <Button className={!tipPercentage && !customTip ? 'searchDateGradientBtn w-20 mt-2' : 'searchDateGradientBtn1 w-20 mt-2'} style={{ marginRight: '2%' }} onClick={() => getSplitUpInfo()}>None</Button>
                        {tips.map((tips) => (
                          <Button
                            key={tips.tip}
                            className={tipPercentage === tips.tip  && !customTip && tipType === tips.tipType ? 'searchDateGradientBtn w-20 mt-2' : 'searchDateGradientBtn1 w-20 mt-2'}
                            style={{ marginRight: '2%' }}
                            onClick={() => getSplitUpInfo(tips.tipType,tips.tip)}
                            >
                              {/* for showing dollar symbol first and % next to amount */}
                            {tips.tipType === "AMOUNT" ? `$${tips.tip}` : `${tips.tip}%`}
                          </Button>
                        ))}

                        <Button className={!tipPercentage && customTip ? 'searchDateGradientBtn w-20 mt-2' : 'searchDateGradientBtn1 w-20 mt-2'}  onClick={() => { setCustomTip(!customTip), setTipPercentage() }}>Custom</Button>

                      </Col>
                      {
                        customTip && (
                          <Col span={24} className="justify-content-center align-items-center mb-2">
                            <Input
                              placeholder="Enter Tip"
                              style={{ marginRight: '2%', width: '75%' }}
                              value={amount}
                              prefix="$"
                              placeholder="0.00"
                              onChange={(e) => {
                                const regex = /^\d*\.?\d*$/;
                                const { value } = e.currentTarget;
                                if (regex.test(value) || value === '') {
                                  setAmount(e.currentTarget.value);
                                } else if (!regex.test(value)) {
                                  setAmount(0.00);
                                }
                              }}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  const value = formatAmount(e.currentTarget.value, 'US');
                                  setAmount(value);
                                }
                              }}
                            />
                            <Button className='searchDateGradientBtn1 w-20' onClick={() => getSplitUpInfo('AMOUNT', amount)}>Add</Button>
                          </Col>
                        )
                      }
                    </Row>
                    <hr />
                    <Row type="flex" justify="space-between">
                      <Col span={12} className="justify-content-center align-items-center mb-2">
                        <SpText fontSize="16px" color="#000000" fontWeight="700" >Order Total</SpText>
                      </Col>
                      <Col span={12} className="justify-content-center align-items-center mb-2" style={{ textAlign: 'right', paddingRight: '25px' }}>
                        <SpText fontSize="16px" color="#000000" fontWeight="700" >$ {splitUpInfo && splitUpInfo.netPayable && (splitUpInfo.netPayable / 100).toFixed(2)}</SpText>
                      </Col>
                    </Row>
                    {
                      payAtStore ? (
                        <Row type="flex" justify="space-between" className='mt-2'>
                          <Col span={24}>
                            <Button className='searchDateGradientBtn w-100' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => setIsProfileVisible(true)} disabled={!isShopOpen}>
                              Pay at Store
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )
                    }
                    <Row type="flex" justify="space-between" className='mt-2 mb-5'>
                      <Col span={24}>
                        <Button className='searchDateGradientBtn w-100' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => completeOrder()} disabled={!isShopOpen}>
                          Pay
                        </Button>
                      </Col>
                    </Row>
                    <Row type="flex" justify="space-between" className='mt-2'>
                      <Col span={24}>
                        <span style={{ height: '38px', fontSize: '18px', fontWeight: '500' }}>
                          &nbsp;
                        </span>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ backgroundColor: '#F3F3F3' }}>
                <div style={{ backgroundColor: '#fff' }}>
                  <Row type="flex" justify="space-between" align="middle">
                    <Col span={24} className="d-flex justify-content-end align-items-center">
                      <img src={CloseIcon} height='20' width='20' onClick={() => getCartVisible(false)} style={{ cursor: 'pointer' }} />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between pt-3">
                    <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                      <img src={localStorage.getItem('banner') ? localStorage.getItem('banner') : ''} width={isMobileView ? "100" : "100"} />
                    </Col>
                  </Row>
                  <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex justify-content-center align-items-center mb-2">
                      <SpText fontSize="22px" color="#E3007B" fontWeight="700"><img src={CartLogo} width={isMobileView ? "40" : "40"} /> &nbsp; My Orders</SpText>
                    </Col>
                  </Row>
                </div>
                <div style={{ height: isMobileView ? 'calc(100vh - 35vh)' : 'calc(100vh - 35vh)', overflowY: 'scroll' }}>
                  <div className="mx-2 my-2 mb-3 px-md-2 px-3 py-2" style={{ borderRadius: '10px', backgroundColor: '#fff' }}>
                    <Row type="flex" justify="space-between">
                      <Col span={24} className="justify-content-center align-items-center mb-2">
                        <SpText fontSize="16px" color="#000000" fontWeight="700">Your items</SpText>
                      </Col>
                    </Row>
                    {
                      itemsInfos && itemsInfos.length !== 0 && (
                        <>
                          {
                            itemsInfos.map((orders, index) => (
                              <>
                                <Row type="flex" justify="space-between" className="align-items-start">
                                  <Col span={orders.isExst ? 10 : 8} className="justify-content-center align-items-center mb-2">
                                    <div>
                                      <SpText fontSize="16px" color={orders.isExst ? '#00BA3F' : "#000000"} fontWeight="600">{orders.onlineName && orders.onlineName.length !==0 ? orders.onlineName : orders.name}</SpText>
                                    </div>
                                    {
                                      orders.isExst ? (
                                        <>
                                          {
                                            orders.coupon && orders.coupon.length !== 0 ? (
                                              <>
                                                {
                                                  orders.coupon[0].percentage ? (
                                                    <div>
                                                      <SpText fontSize="16px" color="#FF0000" fontWeight="400">% {orders.coupon[0].percentage} Off</SpText>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {(orders.coupon[0].amount / 100).toFixed(2)} Off</SpText>
                                                    </div>
                                                  )
                                                }
                                              </>
                                            ) : (
                                              <></>
                                            )
                                          }
                                        </>
                                      ) : (
                                        <>
                                          {
                                            orders.coupon ? (
                                              <>
                                                {
                                                  orders.coupon.percentageOff ? (
                                                    <div>
                                                      <SpText fontSize="16px" color="#FF0000" fontWeight="400">% {orders.coupon.percentageOff} Off</SpText>
                                                    </div>
                                                  ) : (
                                                    <div>
                                                      <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {(items.coupon.amountOff / 100).toFixed(2)} Off</SpText>
                                                    </div>
                                                  )
                                                }
                                              </>
                                            ) : (
                                              <></>
                                            )
                                          }
                                        </>
                                      )
                                    }
                                    <div>
                                      {
                                        orders.price > 0 ? (
                                          <SpText fontSize="14px" color="#000000" fontWeight="400">$ {(orders.price / 100).toFixed(2)}</SpText>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      {/* <SpText fontSize="16px" color="#000000" fontWeight="400">$ {(orders.price / 100).toFixed(2)}</SpText> */}
                                    </div>
                                  </Col>
                                  {
                                    orders.isExst ? (
                                      <Col span={6} className="justify-content-center align-items-center mb-2">

                                        <SpText fontSize="22px" color='#5C5C5C' fontWeight='600' style={{ cursor: 'pointer', padding: '0px 10px' }}>&nbsp;</SpText>
                                        <SpText fontSize="18px" color='#00BA3F' fontWeight='600' style={{ padding: '0px 10px' }}>&nbsp;</SpText>

                                        <SpText fontSize="22px" color='#5C5C5C' fontWeight='600' style={{ cursor: 'pointer', padding: '0px 10px' }}>&nbsp;</SpText>
                                      </Col>
                                    ) : (
                                      <Col span={8} className="justify-content-center align-items-start mb-2">
                                        <SpText fontSize="22px" color='#000000' fontWeight='600' onClick={() => reduceQuant(orders.itemGid, index)} style={{ cursor: 'pointer', padding: '0px 10px' }}>&ndash;</SpText>
                                        <SpText fontSize="18px" color='#000000' fontWeight='600' style={{ padding: '0px 10px' }}>{orders.quantity}</SpText>
                                        <SpText fontSize="22px" color='#000000' fontWeight='600' onClick={() => incrseQuant(orders.itemGid, index)} style={{ cursor: 'pointer', padding: '0px 10px' }}>+</SpText>
                                      </Col>

                                    )
                                  }
                                  {
                                    orders.isExst ? (
                                      <Col span={8} className="justify-content-end align-items-center mb-2 px-3">
                                        <div className="d-flex justify-content-end">
                                          <SpText fontSize="16px" color='#00BA3F' fontWeight="700">$ {orders.totalAmount ? (orders.totalAmount / 100).toFixed(2) : (orders.price / 100).toFixed(2)}</SpText>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                          <SpText fontSize="14px" color="#757575" fontWeight="400">x{orders.quantity}</SpText>
                                        </div>
                                      </Col>
                                    ) : (
                                      <Col span={8} className="justify-content-end align-items-center mb-2 px-3">
                                        <div className="d-flex justify-content-end">
                                          <SpText fontSize="16px" color="#000000" fontWeight="700">$ {(((orders.price * orders.quantity) + (orders.quantity * (orders.lineItemModifiers && orders.lineItemModifiers.length !== 0 ? orders.lineItemModifiers.reduce((n, { quantity, price }) => n + quantity * price, 0) : 0))) / 100).toFixed(2)}</SpText>
                                        </div>

                                        <div className="d-flex justify-content-end">
                                          <SpText fontSize="14px" color="#757575" fontWeight="400">x{orders.quantity}</SpText>
                                        </div>
                                      </Col>
                                    )
                                  }
                                  {
                                    orders.lineItemModifiers && orders.lineItemModifiers.length !== 0 && (
                                      <>
                                        <Col span={8} className="justify-content-center align-items-center mb-2">
                                          <SpText fontSize="16px" color="#00378C" fontWeight="500"><img src={AddOn} /> Add-on</SpText>
                                        </Col>
                                        {
                                          orders.lineItemModifiers.map((item) => (
                                            <>
                                              <Col span={24} className="justify-content-center align-items-center mb-2">
                                                <>
                                                  {
                                                    orders.isExst ? (
                                                      <Row>
                                                        <Col span={24} className="justify-content-center align-items-center mb-2">
                                                          <div>
                                                            {
                                                              item.price > 0 ? (
                                                                <SpText fontSize="16px" color="#5C5C5C" fontWeight="500">{orders.isExst ? item.modifierName : item.name} x {item.quantity} (+ $ {(((item.price ? item.price : 0) * item.quantity) / 100).toFixed(2)})</SpText>
                                                              ) : (
                                                                <SpText fontSize="16px" color="#5C5C5C" fontWeight="500">{orders.isExst ? item.modifierName : item.name} x {item.quantity}</SpText>
                                                              )
                                                            }
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                    ) : (
                                                      <Row>
                                                        <Col span={8} className="justify-content-center align-items-center" >
                                                          <div>
                                                            <SpText fontSize="14px" color="#5C5C5C" fontWeight="500">{orders.isExst ? item.modifierName : item.name}</SpText>
                                                          </div>
                                                          <div>
                                                            {
                                                              item.price > 0 ? (
                                                                <SpText fontSize="14px" color="#5C5C5C" fontWeight="500">$ {(item.price / 100).toFixed(2)}</SpText>
                                                              ) : (
                                                                <></>
                                                              )
                                                            }
                                                          </div>
                                                        </Col>
                                                        <Col span={10} className="justify-content-center align-items-center">
                                                          <SpText fontSize="18px" color='#5C5C5C' fontWeight='500' style={{ padding: '0px 10px' }}>{item.quantity}</SpText>
                                                        </Col>
                                                        <Col span={6} className="justify-content-center align-items-center" >
                                                          <div style={{ textAlign: 'left' }}>
                                                            {
                                                              item.price > 0 ? (
                                                                <SpText fontSize="16px" color="#5C5C5C" fontWeight="500" >+ $ {(((item.price ? item.price : 0) * item.quantity) / 100).toFixed(2)}</SpText>
                                                              ) : (
                                                                <></>
                                                              )
                                                            }
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                    )
                                                  }
                                                </>
                                              </Col>
                                            </>
                                          ))
                                        }
                                      </>
                                    )
                                  }
                                  {
                                    orders.isExst ? (
                                      <></>
                                    ) : (
                                      <Col span={24} className="justify-content-center align-items-center mb-2">
                                        <SpText fontSize="16px" color="#00378C" fontWeight="500" style={{ cursor: 'pointer' }} onClick={() => editSelectedItem(orders, index)}>Edit</SpText>
                                      </Col>
                                    )
                                  }
                                  {
                                    !orders.isExst && orders.note ? (
                                      <Col span={24} className="justify-content-center align-items-center mb-2">
                                        <div style={{ border: '1px solod #C2C2C2', padding: '5px', backgroundColor: '#F3F3F3', borderRadius: '10px' }}>
                                          <SpText fontSize="16px" color="#515151" fontWeight="500" style={{ fontStyle: 'italic' }}>Special instructions: {orders.note}</SpText>
                                        </div>
                                      </Col>
                                    ) : (
                                      <>
                                        {
                                          orders.isExst && orders.note ? (
                                            <Col span={24} className="justify-content-center align-items-center mb-2">
                                              <SpText fontSize="16px" color="#515151" fontWeight="500" style={{ fontStyle: 'italic' }}>Special instructions: {orders.note}</SpText>
                                            </Col>
                                          ) : (
                                            <></>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </Row>
                                <hr />
                              </>
                            ))
                          }
                        </>
                      )
                    }
                    <Row type="flex" justify="space-between mb-5">
                      <Col span={24} className="justify-content-center align-items-center mb-4">
                        <SpText fontSize="14px" color="#000000" fontWeight="600" style={{ cursor: 'pointer' }} onClick={() => getCartVisible(false)}>+ Order more items</SpText>
                      </Col>
                    </Row>
                  </div>
                </div>
                <div className="pt-3" style={{ backgroundColor: '#fff' }}>
                  <Row className='mt-2'>
                    <Col span={24}>
                      <Button className='searchDateGradientBtn w-100 searchDateGradientBtn-mbl' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => setIsOrderPlaced(true)} disabled={!isShopOpen}>
                        Pay Order
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            )
          }
        </>
      </Modal>
      {
        !isCartVisible && itemsInfos.length !== 0 && (
          <div className='d-flex justify-content-center' style={{ position: 'fixed', bottom: '0', textAlign: 'center', width: '100vw', left: '0' }}>
            <Button className='searchDateGradientBtn w-20 mb-2' style={{ height: '38px', fontSize: '18px', fontWeight: '500' }} onClick={() => { getCartVisible(true), setIsOrderPlaced(false); }} disabled={!isShopOpen}>
              <img src={checkoutCart} width='30' />&nbsp;&nbsp; {itemsInfos.length} items &nbsp;&nbsp;|&nbsp;&nbsp; $ {splitUpInfo && splitUpInfo.subTotal && (splitUpInfo.subTotal / 100).toFixed(2)} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img src={ArrowCart} width='30' />
            </Button>
          </div>
        )
      }
    </Card>
  );
}


const mapStateToProps = (state) => ({
  isCartVisible: state.cartInfo.isCartVisible,
  shopInfo: state.cartInfo.shopInfo,
  itemsInfos: state.cartInfo.itemsInfos,
  isMobileView: state.cartInfo.isMobileView,
  inVentData: state.cartInfo.inVentData,
  userInfo: state.cartInfo.userInfo,
  quickSupport: state.cartInfo.quickSupport,
  searchItems: state.cartInfo.searchItems,
  searchActive: state.cartInfo.searchActive,
  selectedShopInfo: state.cartInfo.selectedShopInfo,
  selectedShopInfoTemp: state.cartInfo.selectedShopInfoTemp,
  isShopOpen: state.cartInfo.isShopOpen,
});

const mapDispatchToProps = (dispatch) => ({
  getCartVisible: param => dispatch(cartActions.getCartVisible({
    payload: param,
  })),
  getShopInfo: param => dispatch(cartActions.getShopInfo({
    payload: param,
  })),
  getCustomerInfo: param => dispatch(cartActions.getCustomerInfo({
    payload: param,
  })),
  getItemsInfo: param => dispatch(cartActions.getItemsInfo({
    payload: param,
  })),
  getInventData: param => dispatch(cartActions.getInventData({
    payload: param,
  })),
  requestQuickSupports: param => dispatch(cartActions.requestQuickSupports({
    payload: param,
  })),
  getUserInfo: param => dispatch(cartActions.getUserInfo({
    payload: param,
  })),
  getSearchItems: param => dispatch(cartActions.getSearchItems({
    payload: param,
  })),
  setSearchItems: param => dispatch(cartActions.setSearchItems({
    payload: param,
  })),
  setSelectedShopInfo: param => dispatch(cartActions.setSelectedShopInfo({
    payload: param,
  })),
  setSelectedShopInfoTemp: param => dispatch(cartActions.setSelectedShopInfoTemp({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnlineOrders);
