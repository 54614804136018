// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    Row,
    Col,
    Spin,Button
} from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { SpH5, SpError, SpButton, SpText } from '../../../components/DesignKit';
import CloseIcon from "../../../assets/imgs/close.svg";

type Props = {
    close: Function,
    visible: Boolean,
};

const Unavailable = (props: Props) => {
    const {
        visible,
        close,
    } = props;

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            footer={null}
            onCancel={() =>close()}
        >
            <div class="d-flex justify-content-end align-items-center">
                <img src={CloseIcon} height='20' width='20' onClick={() => close()} style={{ cursor: 'pointer' }} />
            </div>
            <Row justify="space-between justify-content-center align-items-center">
              <Col span={24} className="d-flex justify-content-center align-items-center mb-3">
              <ExclamationCircleOutlined style={{ color: 'red', fontSize: '36px' }} />
              </Col>
              <Col span={24} className="justify-content-center align-items-center mb-2">
                <SpText fontSize='18px' color="#000000" fontWeight="500">
                This Item is currently unavailable, consider choosing other Items or please order during availability period
                </SpText>
              </Col>
            </Row>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    userInfo: state.cartInfo.userInfo,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(Unavailable);
