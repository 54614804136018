import { CART } from '../actionTypes';

const initialState = {
  isCartVisible: false,
  shopInfo: [],
  customerInfo: {},
  itemsInfos: [],
  isMobileView: false,
  inVentData: [],
  userInfo: {},
  quickSupport: '',
  searchItems: [],
  searchActive: false,
  selectedShopInfo: {},
  selectedShopInfoTemp: {},
  isShopOpen: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CART.SET_VISIBLE:
      return {
        ...state,
        isCartVisible: action.payload,
      };
      case CART.SHOP_INFO:
      return {
        ...state,
        shopInfo: action.payload,
      };
      case CART.CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.payload,
      };
      case CART.ITEMS_INFO:
      return {
        ...state,
        itemsInfos: action.payload,
      };
      case CART.GET_MOBILE_VIEW:
      return {
        ...state,
        isMobileView: action.payload,
      };
      case CART.GEI_INVENTORY_ORDER:
      return {
        ...state,
        inVentData: action.payload,
      };
      case CART.USER_PROFILE:
      return {
        ...state,
        userInfo: action.payload,
      };
      case CART.QUICK_SUPPORT:
      return {
        ...state,
        quickSupport: action.payload,
      };
      case CART.SEARCH_ITEMS:
      return {
        ...state,
        searchItems: action.payload,
      };
      case CART.SEARCH_SET:
      return {
        ...state,
        searchActive: action.payload,
      };
      case CART.SELECTED_SHOP_INFO:
      return {
        ...state,
        selectedShopInfo: action.payload,
      };
      case CART.SELECTED_SHOP_INFO_TEMP:
      return {
        ...state,
        selectedShopInfoTemp: action.payload,
      };
      case CART.SHOP_OPEN:
      return {
        ...state,
        isShopOpen: action.payload,
      };
    default:
      return state;
  }
};