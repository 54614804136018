// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Input,
    Select,
    Modal,
    Row,
    Col,
    Button,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import {
    cartActions,
    notificationActions,
} from '../../../store/actions';
import { SpH5, SpError, SpButton, SpText } from '../../../components/DesignKit';
import CloseIcon from "../../../assets/imgs/close.svg";
import { COUNTRY_PHONE_CODE } from '../../../appconstants';

const { Option } = Select;

type Props = {
    close: Function,
    submit: Function,
    visible: Boolean,
    isMobileView: Boolean,
};

const Profile = (props: Props) => {
    const {
        visible,
        close,
        isMobileView,
        submit,
    } = props;
    const [, forceUpdate] = useState();
    const [countryCode, setCountryCode] = useState('+1');
    const [phoneFormate, setPhoneFormate] = useState();
    const [phoneNumber, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const simpleValidator = useRef(new SimpleReactValidator({
        validators: {
            phoneNumValidation: {
                message: 'Enter valid phone number',
                rule: (val, param) => (isValidPhoneNumber(param[0])),
            },
            cust: {
                message: 'Name must be min 4 charecters',
                rule: (val) => val.length >= 4,
            },
        },
    }));

    const onCountryCodeChange = (value) => {
        setCountryCode(value);
        const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
    };

    const onPhoneNumberChange = (e) => {
        const updatedPhone = e.currentTarget.value;
        setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
        setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
    };

    const countryCodeValidation = (e) => {
        const keyCode = (e.which) ? e.which : e.keyCode;
        if (keyCode !== 43 && keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
            e.preventDefault();
        }
    };

    // simpleValidator.current.purgeFields();

    const updateCustomer = (event) => {
        setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
        event.preventDefault();
        const formValid = simpleValidator.current.allValid();
        if (!formValid) {
            simpleValidator.current.showMessages();
            forceUpdate(1);
            return;
        }
        const payload = {
            name: name,
            phoneNumber: `${countryCode}${phoneNumber}`,
            email: email,
        }
        submit(payload);  
    };


    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            footer={null}
        >
            <div class="d-flex justify-content-end align-items-center">
                <img src={CloseIcon} height='20' width='20' onClick={() => close()} style={{ cursor: 'pointer' }} />
            </div>
            <div style={{ borderRadius: '10px' }}>
                <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex align-items-center mt-2" style={{ padding: '0 10px 0 10px' }}>
                        <SpText fontSize="14px" color="#5C5C5C" fontWeight="500">Name</SpText>
                    </Col>
                    <Col span={24} className="align-items-center mb-2" style={{ padding: '0 10px 0 10px' }}>
                        <Row>
                            <Col span={24} className="d-flex align-items-center justify-content-start">
                                <Input type='text'
                                    placeholder='Enter name'
                                    defaultValue={name}
                                    style={{ height: '45px' }}
                                    onChange={(e) => setName(e.currentTarget.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="d-flex align-items-center justify-content-start">
                                <SpError>
                                    {simpleValidator.current.message('name', name, 'required|cust')}
                                </SpError>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex align-items-center mt-2" style={{ padding: '0 10px 0 10px' }}>
                        <SpText fontSize="14px" color="#5C5C5C" fontWeight="500">Email</SpText>
                    </Col>
                    <Col span={24} className="align-items-center mb-2" style={{ padding: '0 10px 0 10px' }}>
                        <Row>
                            <Col span={24} className="d-flex align-items-center justify-content-start">
                                <Input type='text'
                                    placeholder='Enter email'
                                    defaultValue={email}
                                    style={{ height: '45px' }}
                                    onChange={(e) => setEmail(e.currentTarget.value)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className="d-flex align-items-center justify-content-start">
                                <SpError>
                                    {simpleValidator.current.message('email', email, 'required|email')}
                                </SpError>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between">
                    <Col span={24} className="d-flex align-items-center mt-2" style={{ padding: '0 10px 0 10px' }}>
                        <SpText fontSize="14px" color="#5C5C5C" fontWeight="500">Phone Number</SpText>
                    </Col>
                    <Col span={24} className="align-items-center" style={{ padding: '0 10px 10px 10px' }}>
                        <div className="d-flex">
                            <div
                                style={{ width: isMobileView ? '40%' : '30%' }}
                                onKeyPress={e => countryCodeValidation(e)}
                            >
                                <Select
                                    className="w-100"
                                    showSearch
                                    style={{ height: '45px' }}
                                    defaultValue={countryCode}
                                    onChange={onCountryCodeChange}
                                >
                                    {COUNTRY_PHONE_CODE.map((item) => (
                                        <Option key={item} value={item}>
                                            {item}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div style={{ width: isMobileView ? '60%' : '70%' }}>
                                <Input
                                    className="InputnumFormate"
                                    placeholder="Phone Number"
                                    value={phoneFormate}
                                    style={{ height: '45px' }}
                                    onChange={onPhoneNumberChange}
                                    maxLength={10}
                                    onBlur={e => {
                                        if (e.currentTarget.value) {
                                            setPhone(e.currentTarget.value);
                                            const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                                            const formater = value.substr(value.indexOf(' ') + 1);
                                            setPhoneFormate(formater);
                                        }
                                    }}
                                />
                            </div>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className="d-flex align-items-center justify-content-start">
                        <SpError>
                            {simpleValidator.current.message('phone number', phoneNumber, `required|phoneNumValidation:${countryCode}${phoneNumber}`)}
                        </SpError>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Button className='searchDateGradientBtn w-100 ' style={{ height: '45px', fontSize: '18px', fontWeight: '500' }} onClick={(e) => updateCustomer(e)}>
                            <Row type="flex" justify="space-between">
                                <Col span={24} className='justify-content-start align-items-center'>
                                    <span style={{ fontSize: '18px', fontWeight: '600' }}>Submit</span>
                                </Col>
                            </Row>
                        </Button>

                    </Col>
                </Row>
            </div>



        </Modal>
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.cartInfo.isMobileView,
    inVentData: state.cartInfo.inVentData,
    userInfo: state.cartInfo.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
    getItemsInfo: param => dispatch(cartActions.getItemsInfo({
        payload: param,
    })),
    getUserInfo: param => dispatch(cartActions.getUserInfo({
        payload: param,
    })),
    setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
        type,
        payload,
    })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
