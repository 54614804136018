import { TweenLite } from "gsap";
import React, { useEffect, useRef, useState } from "react";
import {
  SpText,
} from '../../../components/DesignKit';

import "./NavBar.css";

function moveEl(target, xVal, yVal) {
  TweenLite.to(target, 0.01, { x: "+=" + xVal, y: "+=" + yVal });
}

function resetMove(target, xVal, yVal) {
  TweenLite.to(target, 1, { x: xVal, y: yVal });
}

/**
 * @param {number} currentPosition Current Scroll position
 * @param {Array} sectionPositionArray Array of positions of all sections
 * @param {number} startIndex Start index of array
 * @param {number} endIndex End index of array
 * @return {number} Current Active index
 */
function nearestIndex(
  currentPosition,
  sectionPositionArray,
  startIndex,
  endIndex
) {
  if (startIndex === endIndex) return startIndex;
  else if (startIndex === endIndex - 1) {
    if (
      Math.abs(
        sectionPositionArray[startIndex] && sectionPositionArray[startIndex].inventoryCategory &&
        sectionPositionArray[startIndex].inventoryCategory.headerRef &&
        sectionPositionArray[startIndex].inventoryCategory.headerRef.current && sectionPositionArray[startIndex].inventoryCategory.headerRef.current.offsetTop -
          currentPosition
      ) <
      Math.abs(
        sectionPositionArray[endIndex] && sectionPositionArray[endIndex].inventoryCategory &&
        sectionPositionArray[endIndex].inventoryCategory.headerRef &&
        sectionPositionArray[endIndex].inventoryCategory.headerRef.current && sectionPositionArray[endIndex].inventoryCategory.headerRef.current.offsetTop -
          currentPosition
      )
    )
      return startIndex;
    else return endIndex;
  } else {
    var nextNearest = ~~((startIndex + endIndex) / 2);
    var a = Math.abs(
      sectionPositionArray[nextNearest] && sectionPositionArray[nextNearest].inventoryCategory && 
      sectionPositionArray[nextNearest].inventoryCategory.headerRef && 
      sectionPositionArray[nextNearest].inventoryCategory.headerRef.current && sectionPositionArray[nextNearest].inventoryCategory.headerRef.current.offsetTop -
        currentPosition
    );
    var b = Math.abs(
      sectionPositionArray[nextNearest + 1] && sectionPositionArray[nextNearest + 1].inventoryCategory &&
      sectionPositionArray[nextNearest + 1].inventoryCategory.headerRef &&
      sectionPositionArray[nextNearest + 1].inventoryCategory.headerRef.current && sectionPositionArray[nextNearest + 1].inventoryCategory.headerRef.current.offsetTop -
        currentPosition
    );
    if (a < b) {
      return nearestIndex(
        currentPosition,
        sectionPositionArray,
        startIndex,
        nextNearest
      );
    } else {
      return nearestIndex(
        currentPosition,
        sectionPositionArray,
        nextNearest,
        endIndex
      );
    }
  }
}

function NavBarMenu({ navHeader, testing, validateFunc, isMobileView, scrollFunc, scrollPosition }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [enableDrag, setEnableDrag] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [resetPosition, setResetPosition] = useState();

  if (testing && testing.length > 0) {
    for (let i = 0; i < testing.length; i += 1) {
      testing[i].inventoryCategory.headerRef = useRef();
      }
  }
  useEffect(() => {
      validateFunc(testing);
  }, [testing]);

  const containerRef = useRef();
  const navContainerRef = useRef();
  const navItemRef = useRef([]);

  const positionRef = useRef(position);
  const navContainerStateRef = useRef(enableDrag);
  const resetPositionRef = useRef(resetPosition);

  const setPositionStateRef = (data) => {
    positionRef.current = data;
    setPosition(data);
  };
  const setEnableDragState = (data) => {
    navContainerStateRef.current = data;
    setEnableDrag(data);
  };
  function setResetPositionState(data) {
    resetPositionRef.current = data;
    setResetPosition(data);
  }

  function inView(child, parent) {
    return child.x >= 0 && child.right <= parent.right;
  }

  function moveTo(index) {
    const child = navItemRef.current[index].getBoundingClientRect();
    const parent = containerRef.current.getBoundingClientRect();
    if (!inView(child, parent)) {
      if (child.left < 0) moveEl(navContainerRef.current, child.left * -1, 0);
      else
        moveEl(
          navContainerRef.current,
          parent.width - child.right + parent.left,
          0
        );
    }
  }

  function clickStart(event) {
    setEnableDragState(true);
    setPositionStateRef({
      x: event.offsetX,
      y: event.offsetY
    });
  }

  function clickMoving(event) {
    if (navContainerStateRef.current)
      if (
        event.movementX > 0 &&
        event.movementX + navItemRef.current[0].getBoundingClientRect().left >=
          containerRef.current.getBoundingClientRect().left
      ) {
        resetMove(navContainerRef.current, 0, 0);
      } else if (
        event.movementX < 0 &&
        event.movementX +
          navItemRef.current[
            navItemRef.current.length - 1
          ].getBoundingClientRect().right <=
          containerRef.current.getBoundingClientRect().right
      ) {
        resetMove(navContainerRef.current, resetPositionRef.current, 0);
      } else {
        moveEl(navContainerRef.current, event.movementX, 0);
      }
  }

  function movementStart(event) {
    setEnableDragState(true);
    setPositionStateRef({
      x: event.touches[0].clientX,
      y: event.touches[0].clientY
    });
  }

  function moving(event) {
    const movementX = event.touches[0].clientX - positionRef.current.x;
    setPositionStateRef({
      x: event.touches[0].clientX,
      y: event.touches[0].clientY
    });

    if (navContainerStateRef.current)
      if (
        movementX > 0 &&
        movementX + navItemRef.current[0].getBoundingClientRect().left >=
          containerRef.current.getBoundingClientRect().left
      ) {
        resetMove(navContainerRef.current, 0, 0);
      } else if (
        movementX < 0 &&
        movementX +
          navItemRef.current[
            navItemRef.current.length - 1
          ].getBoundingClientRect().right <=
          containerRef.current.getBoundingClientRect().right
      ) {
        resetMove(navContainerRef.current, resetPositionRef.current, 0);
      } else {
        moveEl(navContainerRef.current, movementX, 0);
      }
  }

  function movementEnd(event) {
    setEnableDragState(false);
  }

  useEffect(() => {
    navContainerRef.current.addEventListener("mousedown", clickStart);
    navContainerRef.current.addEventListener("mousemove", clickMoving);
    navContainerRef.current.addEventListener("mouseup", movementEnd);

    /*
    ontouchend 
ontouchmove 	
ontouchstart
    */
    navContainerRef.current.addEventListener("touchcancel", (event) => {
      alert("canceled");
    });
    navContainerRef.current.addEventListener("touchstart", (event) =>
      movementStart(event)
    );
    navContainerRef.current.addEventListener("touchmove", (event) =>
      moving(event)
    );
    navContainerRef.current.addEventListener("touchend", (event) =>
      movementEnd(event)
    );
  }, []);

  function gotoURL(section, id) {
    moveTo(id);
    setActiveIndex(id);
    scrollFunc(section);
  }

  useEffect(() => {
    if (navHeader && navHeader.length !== 0) {
    const handleScroll = (e) => {
      var index = nearestIndex(
        window.scrollY,
        navHeader,
        0,
        navHeader.length - 1
      );
      setActiveIndex(index);
      moveTo(index);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
}
  }, [navHeader]);

  useEffect(() => {
    if (navHeader && navHeader.length !== 0) {
        setResetPositionState(
            containerRef.current.getBoundingClientRect().width -
              (navItemRef.current[
                navItemRef.current.length - 1
              ].getBoundingClientRect().right -
                navItemRef.current[0].getBoundingClientRect().left)
          );
    }
    
  }, [navHeader]);

  useEffect(() => {
    navItemRef.current = navItemRef.current.slice(0, navHeader.length);
  }, [navHeader]);

  return (
    <>
      <div className="cat-container" ref={containerRef} >
        <div className="navContainer" ref={navContainerRef} style={{height: isMobileView ? '120px' : '115px' }}>
          {navHeader.map((headerItem, index) => (
            <div
              className="navLink"
              key={index + headerItem.inventoryCategory.gid}
              ref={(ref) => (navItemRef.current[index] = ref)}
              id={headerItem.inventoryCategory.gid + index}
              style={{
                color: activeIndex === index && "#00378C",
                borderBottom: activeIndex === index && '3px solid #00378C',
                //paddingBottom: '15px',
                width: 'auto',
                borderRadius:activeIndex === index && '5px 5px 0px 0px',
                // backgroundColor: activeIndex === index && "yellowgreen"
                transform: `translateX(${scrollPosition}px)`
              }}
            >
              <div className="navLinkContent text-center" style={{width: isMobileView ? '150px' : '150px',  cursor: 'pointer',fontSize: '16px'}}>
                <div
                  key={index + headerItem.inventoryCategory.gid}
                  // onClick={()=>
                  // window.location = "http://www.google.com/";}
                  href={`#${headerItem.inventoryCategory.gid}`}
                >
                  <div onClick={() => gotoURL(headerItem.inventoryCategory.gid, index)}>
                  {
                  headerItem.inventoryCategory.coupon ? (
                    <>
                      {
                        headerItem.inventoryCategory.coupon.percentageOff ? (
                          <SpText fontSize="16px" color="#FFF" fontWeight="400" style={{ backgroundColor: '#00378C', borderRadius: '50px', padding: '0 10px' }}>% {headerItem.inventoryCategory.coupon.percentageOff} Off</SpText>
                        ) : (
                          <SpText fontSize="16px" color="#FF0000" fontWeight="400">$ {(headerItem.inventoryCategory.coupon.amountOff / 100).toFixed(2)} Off</SpText>
                        )
                      }
                    </>
                  ) : (
                    <></>
                  )
                }
                  <br/>
                    {headerItem && headerItem.inventoryCategory.name}<br />
                    {
                      headerItem && headerItem.inventoryCategory.description && (
                        <span style={{ fontSize: '14px', fontWeight: '400' }}>{headerItem.inventoryCategory.description}</span>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div
        onClick={() => {
          resetMove(navContainerRef.current, resetPositionRef.current, 0);
        }}
      >
        reset to back
      </div>
      <div
        onClick={() => {
          moveTo(0);
        }}
      >
        section 1
      </div>
      <div
        onClick={() => {
          moveTo(1);
        }}
      >
        section 2
      </div>
      <div
        onClick={() => {
          moveTo(2);
        }}
      >
        section 3
      </div>
      <div
        onClick={() => {
          moveTo(3);
        }}
      >
        section 4
      </div>
      <div
        onClick={() => {
          moveTo(4);
        }}
      >
        section 5
      </div> */}
    </>
  );
}

export default NavBarMenu;
